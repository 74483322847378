import React, { useState } from "react";
import { Link } from "react-router-dom";

function MobileMenu() {
  const [openmenu, setOpenmenu] = useState(null);
  const [dropdownmenu, setDropdownmenu] = useState(false);
  return (
    <div className="offcanvas offcanvas-start" id="offcanvasNavbar">
      <div className="offcanvas-header">
        {/* Logo Start */}
        <div className="logo">
          <Link TO="/">
            <img
              src="/assets/images/logo.png"
              width={172}
              height={45}
              alt="Logo"
            />
          </Link>
        </div>
        {/* Logo End */}
        <button type="button" className="close" data-bs-dismiss="offcanvas">
          <span />
          <span />
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="mobile-menu offcanvas-menu">
          <ul className="navbar-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">SKIN TREATMENTS</Link>
              <span class="menu-icon"></span>
              <span
                onClick={() => {
                  setOpenmenu(openmenu != "SKIN" ? "SKIN" : null);
                }}
                class="menu-expand"
              ></span>
              <ul
                className="sub-menu"
                style={{ display: openmenu == "SKIN" ? "block" : "none" }}
              >
                <li
                  onClick={() => {
                    setDropdownmenu(!dropdownmenu);
                  }}
                >
                  <a href="#">ANTI-AGING TREATMENT</a>
                  <span class="menu-icon"></span>
                  <span class="menu-expand"></span>
                  <ul
                    className="sub-menu"
                    style={{ display: dropdownmenu ? "block" : "none" }}
                  >
                    <li>
                      <Link to="/our-services/dermal-filler-botox">
                        Dermal Fillers & Botox
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/lip-enhancement">
                        Lip Enhancement
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/chin-enhancement">
                        Chin Enhancement
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/hifu-treatment">
                        HIFU Treatment
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/thread-lift">Thread Lift</Link>
                    </li>
                    <li>
                      <Link to="/our-services/lip-enhancement">
                        Lip Enhancement
                      </Link>
                    </li>

                    <li>
                      <Link to="/our-services/lip-enhancement">
                        Lip Enhancement
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/our-services/skin-rejuvenation">
                    Skin Rejuvenation
                  </Link>
                </li>
                <li>
                  <Link to="/our-services/vampire-facial">Vampire Facial</Link>
                </li>

                <li>
                  <Link to="/our-services/hydra-facial">HydraFacial</Link>
                </li>
                <li>
                  <Link to="/our-services/chemical-peels">Chemical Peel</Link>
                </li>
                <li>
                  <Link to="/our-services/photofacial">Photofacial</Link>
                </li>
                <li>
                  <Link to="/our-services/pigmentation-removal">
                    Pigmentation Removal
                  </Link>
                </li>
                <li>
                  <Link to="/our-services/anti-acne-treatment">
                    Anti-Acne Treatment
                  </Link>
                </li>
                <li>
                  <Link to="/our-services/mesotherapy">Mesotherapy</Link>
                </li>
                <li>
                  <Link to="/our-services/laser-hair-reduction">
                    Laser Hair Reduction
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">HAIR & AYURVEDIC TREATMENTS</Link>
              <span class="menu-icon"></span>
              <span
                onClick={() => {
                  setOpenmenu(openmenu != "HAIR" ? "HAIR" : null);
                }}
                class="menu-expand"
              ></span>
              <ul
                className="sub-menu"
                style={{ display: openmenu == "HAIR" ? "block" : "none" }}
              >
                <li>
                  <Link to="/our-services/hairfall-treatment">
                    Hair Fall Treatment
                  </Link>
                </li>
                <li>
                  <Link to="/our-services/hair-prp-therapy">
                    Hair PRP Therapy
                  </Link>
                </li>
                <li>
                  <Link to="/our-services/ayurvedic-treatment">
                    Ayurvedic Treatments
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Mommy Makeover</a>
              <span class="menu-icon"></span>

              <span
                onClick={() => {
                  setOpenmenu(openmenu != "MOMMY" ? "MOMMY" : null);
                }}
                class="menu-expand"
              ></span>
              <ul
                className="sub-menu"
                style={{ display: openmenu == "MOMMY" ? "block" : "none" }}
              >
                <li>
                  <Link to="/our-services/slimming-inch-loss-treatment">
                    Slimming & Inch Loss
                  </Link>
                </li>
                <li>
                  <Link to="/our-services/cryolipolysis">Cryolipolysis</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="mobi text-center">
          <a
            href="#"
            className="btn appoin"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            BOOK AN APPOINTMENT
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
