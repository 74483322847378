import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function ChemicalPeels() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Chemical Peels"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>CHEMICAL PEEL</strong>
                  </h2>

                  <p>
                    <strong>Swakaaya Wellness</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      offers chemical peels, some peels are right for all skin
                      types, and peels deliver noticeable results to patients of
                      all skin types. If you want to give it a try, then you
                      need to know the specifics of what a peel is, how it
                      works, and how it can benefit your skin.
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Chemical Peeling is a procedure used for the cosmetic
                      improvement of the skin or the treatment of some skin
                      disorders. It can enhance the skin’s appearance by peeling
                      off an older layer of skin to reveal the more youthful and
                      healthy skin underneath. In this treatment, a solution of
                      chemicals is applied to the skin which causes trauma and
                      injury to your skin’s layers. As a result, the dead skin
                      layers come off, revealing more youthful skin. The new
                      skin is usually smoother and less wrinkled than the older
                      one.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>HOW ARE CHEMICAL PEELS DONE?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      During the procedure, first, the doctor will clean your
                      skin thoroughly. Then they’ll apply one or more chemical
                      solutions such as glycolic acid, trichloroacetic acid,
                      salicylic acid, lactic acid, or carbolic acid (phenol) to
                      small areas of your skin. This creates a controlled wound,
                      allowing for the regeneration of new skin.
                    </span>
                  </p>

                  <p>
                    <strong>IS THIS TREATMENT GOOD FOR ALL SKIN TYPES?</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Yes, chemical peel treatment is customizable, so people
                      with all kinds of skin can opt for this treatment.
                      Although dermatologists recommend people with darker skin
                      to take precautions before getting a peel to make sure
                      that the peel is safe for their tone. They are advised to
                      do so because some chemicals can increase
                      hyperpigmentation in their skin.&nbsp;
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Generally, everyone is a good candidate for chemical
                      peeling treatment. However, chemical peels are not
                      recommended for patients who are:
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Pregnant</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Have sunburnt skin
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Have an open wound or sore
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Diagnosed with major diseases
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Have taken Accutane (Isotretinoin) within six months
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>WHAT CAN CHEMICAL PEEL TREAT?</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Chemical peels are effective for skin problems like sun
                      damage, hyperpigmentation, and acne. They’re also used to
                      treat melasma, a pigmentation disorder.
                    </span>
                  </p>

                  <p>
                    <strong>
                      Things to know before you get a chemical peel:
                    </strong>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Before you get a chemical peel, have a one-on-one
                        conversation with your dermatologist and tell him/her if
                        you have any history of scarring, cold sores that keep
                        coming on, or facial X-rays.&nbsp;
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Your dermatologist may ask you to stop taking certain
                        medicines and make your skin ready for treatment by
                        using other medications, such as Retin-A or glycolic
                        acid.&nbsp;
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>
                      If you’re interested in getting a chemical peel or have
                      any skincare questions, contact us today. We would be
                      happy to help you out.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default ChemicalPeels;
