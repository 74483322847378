import React from "react";
import Banner from "../../components/banner/Banner";
import Services from "../services/Services";
import About from "../../components/about/About";
import ServiceSection from "../../components/servicesection/ServiceSection";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import OfferSection from "../../components/offersection/OfferSection";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import Footer from "../common/Footer";
import Header from "../common/Header";

function Home() {
  return (
    <div>
      <div className="main-wrapper">
        {/* Header Start */}
        <Header />
        <Banner />
        <Services />
        <About />
        <ServiceSection />
        <WhyChooseUs />
        <OfferSection />
        <Testimonial />
        <Blog />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
