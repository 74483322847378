import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function SlimmingLossTreatment() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Slimming & Inch Loss Treatment"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>SLIMMING &amp; INCH LOSS TREATMENTS</strong>
                  </h2>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We often see that people don’t feel confident with their
                      bodies. Why? The answer is belly fat. The word ‘fat’ can
                      single-handedly lower one’s confidence. Excess fat is not
                      that healthy, especially in places like hips, thighs, and
                      abdomen. Even after following good exercise and diet, if
                      you don’t have any results in your favor, then you’re at
                      the right place.&nbsp;
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>At </span>
                    <strong>Swakaaya Wellness Clinic</strong>
                    <span style={{ fontWeight: 400 }}>
                      , we help our patients to lose weight healthily and if
                      you’re sick and tired of your fats then you can contact us
                      to book an appointment. Before we talk about the
                      treatments, let’s see what inch loss is.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      It is true that doing exercise and following a healthy
                      diet can help lose weight, but it is not helpful for
                      everyone. This is where inch loss treatment comes to your
                      rescue.&nbsp;&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>WHAT IS SLIMMING &amp; INCH LOSS TREATMENT?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Inch loss treatment uses ultrasound technology to kill
                      fatty cells. This treatment helps to get rid of excess
                      belly fat and fat from arms, thighs, and bums. This
                      procedure is helpful for those who exercise, stay active
                      and want to reduce stubborn fats.&nbsp;
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Generally, during the treatment, a person is required to
                      visit the clinic around 6 times at 2-3 week intervals.
                      Maintaining a healthy diet will ensure that the results
                      are long-lasting.&nbsp;
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      How does Inch Loss treatment work?
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        This treatment involves a combined use of ultrasound and
                        radiofrequency which lasts for an hour.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Ultrasound irritates the fats and breaks down the outer
                        walls of the fat cells.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Radiofrequency stimulates the production of collagen, as
                        a person starts to lose fat, his skin starts to
                        tighten.&nbsp;
                      </span>
                    </li>
                  </ul>
                  <p>
                    <strong>
                      BENEFITS OF SLIMMING &amp; INCH LOSS TREATMENT:
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <span style={{ fontWeight: 400 }}>
                        It helps to get rid of stubborn fats effortlessly.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        It allows us to achieve a more defined body shape than
                        before.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        It helps to prevent the problem of saggy skin.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        It offers long-lasting satisfaction.
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      In the world of Ayurveda, some ingredients promise not
                      only to make us lose stubborn belly fat but also to keep
                      us away from numerous lifestyle diseases. The following is
                      the list of ingredients that can help us lose fat:
                    </span>
                  </p>
                  <p>
                    <strong>Guggul: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Guggul is gum resin obtained from different plants native
                      to India, Pakistan, and Bangladesh. This ingredient is
                      mostly used for weight loss.
                    </span>
                  </p>

                  <p>
                    <strong>Garcinia Cambogia: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Garcinia Cambogia works wonderfully as a fat-loss
                      supplement. It blocks the body’s ability to form fats.
                    </span>
                  </p>
                  <p>
                    <strong>Triphala: </strong>
                    <span style={{ fontWeight: 400 }}>
                      The mixture of haritaki, amla, and bibhitaki is known as
                      Triphala
                    </span>
                    <strong>. </strong>
                    <span style={{ fontWeight: 400 }}>
                      This herbal mixture is useful for treating various
                      ailments. It promotes weight loss by flushing out toxins
                      and improving our digestion.
                    </span>
                  </p>
                  <p>
                    <strong>Dalchini: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Dalchini, also known as cinnamon. It has numerous
                      medicinal properties. This is thought to boost metabolism
                      and help lose weight.
                    </span>
                  </p>
                  <p>
                    <strong>Purnarnava: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Purnarnava is one of the most effective ayurvedic
                      medicines for weight loss. This has diuretic properties
                      which keep our bladder and kidney healthy.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default SlimmingLossTreatment;
