import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { banner_reducer } from "./banner_reducer";
import { newsletter_reducer } from "./newsletter_reducer";
import { product_reducer } from "./product_reducer";
import { wishlist_reducer } from "./wishlist_reducer";
import { cart_reducer } from "./cart_reducer";

export default combineReducers({
  alert,
  auth,
  banner: banner_reducer,
  newsletter: newsletter_reducer,
  product: product_reducer,
  wishlist: wishlist_reducer,
  cart: cart_reducer,
});
