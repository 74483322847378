import React, { useEffect } from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";

import { loadUser } from "./store/actions/auth";
import setAuthToken from "./domain/setAuthToken";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import PageNotFound from "./containers/notfound/PageNotFound";
import Home from "./containers/home/Home";
import AOS from "aos";
import Services from "./containers/services/Services";
import AntiAcneTreatment from "./containers/services/AntiAcneTreatment";
import ChemicalPeels from "./containers/services/ChemicalPeels";
import Cryolipolysis from "./containers/services/Cryolipolysis";
import HairPrpTherapy from "./containers/services/HairPrpTherapy";
import HydraFacial from "./containers/services/HydraFacial";
import LaserHairReduction from "./containers/services/LaserHairReduction";
import Mesotherapy from "./containers/services/Mesotherapy";
import PhotoFacial from "./containers/services/PhotoFacial";
import PigmentationRemoval from "./containers/services/PigmentationRemoval";
import SlimmingLossTreatment from "./containers/services/SlimmingLossTreatment";
import VampireFacial from "./containers/services/VampireFacial";
import LipEnhancement from "./containers/services/LipEnhancement";
import About from "./containers/about/About";
import AyurvedicTreatment from "./containers/services/AyurvedicTreatment";
import ChinEnhancement from "./containers/services/ChinEnhancement";
import DermalFillersBotox from "./containers/services/DermalFillersBotox";
import FaceliftByHifu from "./containers/services/FaceliftByHifu";
import HairFallTreatment from "./containers/services/HairFallTreatment";
import SkinRejuvenation from "./containers/services/SkinRejuvenation";
import ThreadLift from "./containers/services/ThreadLift";
import Contact from "./containers/contact/Contact";
import ThankYou from "./containers/thank-you/ThankYou";

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem("token");
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
    AOS.init({
      once: true,
      duration: 1500,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/our-services" component={Services} />
          <Route exact path="/about-us" component={About} />
          <Route
            exact
            path="/our-services/anti-acne-treatment"
            component={AntiAcneTreatment}
          />
          <Route
            exact
            path="/our-services/chemical-peels"
            component={ChemicalPeels}
          />
          <Route
            exact
            path="/our-services/cryolipolysis"
            component={Cryolipolysis}
          />
          <Route
            exact
            path="/our-services/hair-prp-therapy"
            component={HairPrpTherapy}
          />
          <Route
            exact
            path="/our-services/hydra-facial"
            component={HydraFacial}
          />
          <Route
            exact
            path="/our-services/laser-hair-reduction"
            component={LaserHairReduction}
          />
          <Route
            exact
            path="/our-services/photofacial"
            component={PhotoFacial}
          />
          <Route
            exact
            path="/our-services/pigmentation-removal"
            component={PigmentationRemoval}
          />
          <Route
            exact
            path="/our-services/mesotherapy"
            component={Mesotherapy}
          />
          <Route
            exact
            path="/our-services/slimming-inch-loss-treatment"
            component={SlimmingLossTreatment}
          />
          <Route
            exact
            path="/our-services/vampire-facial"
            component={VampireFacial}
          />
          <Route
            exact
            path="/our-services/lip-enhancement"
            component={LipEnhancement}
          />
          <Route
            exact
            path="/our-services/ayurvedic-treatment"
            component={AyurvedicTreatment}
          />
          <Route
            exact
            path="/our-services/chin-enhancement"
            component={ChinEnhancement}
          />

          <Route
            exact
            path="/our-services/hairfall-treatment"
            component={HairFallTreatment}
          />
          <Route
            exact
            path="/our-services/skin-rejuvenation"
            component={SkinRejuvenation}
          />
          <Route
            exact
            path="/our-services/dermal-filler-botox"
            component={DermalFillersBotox}
          />
          <Route
            exact
            path="/our-services/thread-lift"
            component={ThreadLift}
          />
          <Route
            exact
            path="/our-services/hifu-treatment"
            component={FaceliftByHifu}
          />

          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
