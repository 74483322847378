import React from "react";

function Blog() {
  return (
    <div className="section section-padding">
      <div className="container">
        {/* Blog Wrapper Start */}
        <div className="blog-wrapper">
          <div className="row">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
              {/* Blog Sidebar Title Start */}
              <div className="blog-sidebar-title">
                {/* Section Title Start */}
                <div className="section-title">
                  <h6 className="sub-title">Blog</h6>
                  <h2 className="title">
                    Latest Post <br />
                    from Blog
                    <img
                      className="shape"
                      src="assets/images/shape/shape-15.webp"
                      alt="Shape"
                    />
                  </h2>
                </div>
                {/* Section Title End */}
                {/* <p class="text">
              Deep discuss about Skin Treatment and Therapy Treamment
            </p> */}
              </div>
              {/* Blog Sidebar Title End */}
            </div>
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay={400}
            >
              {/* Single Blog Start */}
              <div className="single-blog">
                <div className="blgo-image">
                  <img
                    src="assets/images/blog-imgs/blog-skincare.png"
                    width={314}
                    height={238}
                    alt="Blog"
                  />
                </div>
                <div className="blgo-content">
                  <ul className="meta">
                    <li>
                      <i className="fa fa-calendar" />
                      12 Oct, 2022
                    </li>
                    {/* <li>
                  <a href="#"><i class="fa fa-user-o"></i> Mahesh</a>
                </li> */}
                  </ul>
                  <h3 className="title">
                    Essential Skin Care Tips for Soft &amp; Glowing Skin in
                    Winter
                  </h3>
                </div>
                <img
                  src="assets/images/shape/shape-4.webp"
                  className="shape"
                  alt="Shape"
                />
              </div>
              {/* Single Blog End */}
            </div>
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay={600}
            >
              {/* Single Blog Start */}
              <div className="single-blog">
                <div className="blgo-image">
                  <img
                    src="assets/images/blog-imgs/blog-hair.png"
                    width={314}
                    height={238}
                    alt="Blog"
                  />
                </div>
                <div className="blgo-content">
                  <ul className="meta">
                    <li>
                      <i className="fa fa-calendar" /> 05 Sept, 2022
                    </li>
                    {/* <li>
                  <a href="#"><i class="fa fa-user-o"></i> Sujit</a>
                </li> */}
                  </ul>
                  <h3 className="title">How Do Hair Treatment</h3>
                </div>
                <img
                  src="assets/images/shape/shape-4.webp"
                  className="shape"
                  alt="Shape"
                />
              </div>
              {/* Single Blog End */}
            </div>
          </div>
        </div>
        {/* Blog Wrapper End */}
      </div>
    </div>
  );
}

export default Blog;
