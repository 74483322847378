import { GET_CART, CARTS_ERROR, TOGGLE_CART } from "../types/cart_type";

const initialState = {
  cart: localStorage.getItem(process.env.REACT_APP_CART)
    ? JSON.parse(localStorage.getItem(process.env.REACT_APP_CART))
    : null,
  loading: true,
  error: {},
  is_open: false,
};

export const cart_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CART:
      return {
        ...state,
        cart: payload,
        loading: false,
        error: {},
      };
    case TOGGLE_CART:
      return {
        ...state,
        is_open: payload,
      };

    case CARTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
