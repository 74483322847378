import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function HairPrpTherapy() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Hair Prp Therapy"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>HAIR PRP THERAPY&nbsp;</strong>
                  </h2>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Having healthy hair is every person’s dream and you can
                      fulfill this dream with{" "}
                    </span>
                    <strong>Swakaaya Wellness Clinic</strong>
                    <span style={{ fontWeight: 400 }}>.&nbsp;</span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Platelet Rich Plasma or PRP Hair Therapy uses a patient’s
                      own blood to promote the healing of hair loss. PRP is
                      taking over the medical field because this is the most
                      natural procedure and your own growth factors that are
                      going to work for your hair growth.
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      It is a revolutionary process that is effective in
                      treating hair loss and makes the problem areas thicker and
                      denser that are being treated.
                    </span>
                  </p>

                  <p>
                    <strong>THE HAIR PRP THERAPY PROCEDURE INCLUDES:</strong>
                  </p>

                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The procedure is performed under local anesthesia.
                        Hence, the procedure is painless.
                      </span>
                    </li>
                  </ul>

                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The blood is extracted from the vein in the arm (approx.
                        30 ml) and placed in a device called a centrifuge
                        machine for around eight to ten minutes to get the
                        plasma separated from the rest of the blood.
                      </span>
                    </li>
                  </ul>

                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The plasma that was separated is injected all over the
                        scalp using a thin needle syringe or microneedle.&nbsp;
                      </span>
                    </li>
                  </ul>

                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The entire process takes around 45 minutes to one hour.
                      </span>
                    </li>
                  </ul>
                  <p></p>
                  <p>
                    <strong>HOW LONG DOES IT TAKE TO SHOW THE RESULTS?</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The results may vary from person to person. It is a
                      customizable procedure as per the requirement of the
                      patients. Usually, results expected from Hair PRP Therapy
                      can begin to show comprehensive progress within a span of
                      three months.
                    </span>
                  </p>
                  <p></p>
                  <p>
                    <strong>WHAT ARE THE BENEFITS OF HAIR PRP THERAPY?</strong>
                  </p>

                  <p>
                    <strong>The procedure has the following benefits:</strong>
                  </p>

                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Natural procedure for hair loss.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Non-Surgical &amp; Safe Procedure.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Convenient process with no cuts.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Little to no downtime.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Long-lasting results.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Customizable procedure.
                      </span>
                    </li>
                  </ul>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      To stay safe, always choose a qualified professional to
                      perform the procedure.&nbsp; While performing the
                      procedure, the dermatologist &amp; therapists should
                      follow proper hygiene techniques and should sterilize the
                      equipment before and after the procedure.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default HairPrpTherapy;
