import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./mobilemenu/MobileMenu";

function Header() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <>
      <section className={scroll ? "top-header fixed" : "top-header"}>
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-md-8">
              <h4>
                Book Now: <a href="tel:918826815442">+91-88268 15442</a>
              </h4>
            </div>
            <div className="col-xs-6 col-md-4">
              <div className="top-header-social">
                <ul className="">
                  <li>
                    <a href="tel:8826815442">
                      <i className="fa fa-phone" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/swakaaya">
                      <i className="fa fa-facebook-f" />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/swakaaya.wellness">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id="header"
        className={
          scroll ? "section header-area fixed " : "section header-area"
        }
      >
        <div className="container-fluid">
          {/* Header Wrapper Start */}
          <div className="header-wrapper">
            {/* Header Logo Start */}
            <div className="header-logo">
              <Link to="/">
                <img
                  src="/assets/images/logo.png"
                  width={172}
                  height={45}
                  alt="Logo"
                />
              </Link>
            </div>
            {/* Header Logo End */}
            {/* Header Navbar Start */}
            <div className="header-navbar d-none d-lg-block ases">
              <ul className="navbar-menu">
                <li>
                  <Link to="/">Home </Link>
                </li>

                <li>
                  <a href="#">SKIN TREATMENTS</a>
                  <span class="menu-icon"></span>
                  <ul class="sub-menu">
                    <li>
                      <a href="#">ANTI-AGING TREATMENT</a>
                      <span class="menu-icon"></span>
                      <ul class="sub-menu">
                        <li>
                          <Link to="/our-services/dermal-filler-botox">
                            Dermal Fillers & Botox
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/lip-enhancement">
                            Lip Enhancement
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/chin-enhancement">
                            Chin Enhancement
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/hifu-treatment">
                            HIFU Treatment
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/thread-lift">
                            Thread Lift
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/our-services/skin-rejuvenation">
                        Skin Rejuvenation
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/vampire-facial">
                        Vampire Facial
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/hydra-facial">HydraFacial</Link>
                    </li>
                    <li>
                      <Link to="/our-services/chemical-peels">
                        Chemical Peel
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/photofacial">Photofacial</Link>
                    </li>
                    <li>
                      <Link to="/our-services/pigmentation-removal">
                        Pigmentation Removal
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/anti-acne-treatment">
                        Anti-Acne Treatment
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/mesotherapy">Mesotherapy</Link>
                    </li>
                    <li>
                      <Link to="/our-services/laser-hair-reduction">
                        Laser Hair Reduction
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="#">HAIR & AYURVEDIC TREATMENTS</a>
                  <span class="menu-icon"></span>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/our-services/hairfall-treatment">
                        Hair Fall Treatment
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/hair-prp-therapy">
                        Hair PRP Therapy
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/ayurvedic-treatment">
                        Ayurvedic Treatments
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Mommy Makeover</a>
                  <span class="menu-icon"></span>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/our-services/slimming-inch-loss-treatment">
                        Slimming & Inch Loss
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-services/cryolipolysis">
                        Cryolipolysis
                      </Link>
                    </li>
                  </ul>
                </li>

                <div className="appi">
                  <a
                    href="#"
                    className="btn appoin"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    BOOK AN APPOINTMENT
                  </a>
                </div>
              </ul>
            </div>

            <div className="header-meta">
              {/* Hewader Toggle Start */}
              <div className="header-toggle d-lg-none">
                <button
                  className="toggle"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>
              {/* Hewader Toggle End */}
            </div>
            {/* Hewader Meta End */}
          </div>
          {/* Header Wrapper End */}
        </div>
      </div>
      <MobileMenu />
    </>
  );
}

export default Header;
