import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
function ServiceSection() {
  return (
    <div
      className="section services-section"
      style={{ backgroundImage: "url(assets/images/patan-bg.webp)" }}
    >
      {/* Services Wrapper Start */}
      <div className="services-wrapper">
        {/* Services Main Content Start */}
        <div
          className="services-main-content"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <img
            className="shape-1 movebounce-02"
            src="assets/images/shape/shape-6.webp"
            alt="Shape"
          />
          <img
            className="shape-2 movebounce-03"
            src="assets/images/shape/shape-7.webp"
            alt="Shape"
          />
          <img
            className="shape-3"
            src="assets/images/shape/shape-8.webp"
            alt="Shape"
          />
          {/* Section Title Start */}
          <div className="section-title text-center">
            {/* <h6 class="sub-title">Service</h6> */}
            <h2 className="title">OUR SERVICES</h2>
            <p>
              IF YOU WANT TO MAKE YOUR SKIN GLOW, YOUR BEST BET IS TO TAKE CARE
              OF IT!
            </p>
          </div>
          {/* Section Title End */}
          <div className="services-active">
            <div className="swiper-container">
              <Swiper
                modules={[Pagination]}
                spaceBetween={30}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                slidesPerView={2}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div className="swiper-slide">
                    {/* Services Start */}
                    <div className="single-services text-center">
                      <div className="services-image">
                        <a href="/our-services/skin-rejuvenation">
                          <img
                            src="assets/images/edited-imgs/skin-treatment-service.png"
                            width={233}
                            height={233}
                            alt="services"
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="/our-services/skin-rejuvenation">SKIN TREATMENT</a>
                        </h4>
                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                      </div>
                    </div>
                    {/* Services End */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    {/* Services Start */}
                    <div className="single-services text-center">
                      <div className="services-image">
                        <a href="/our-services/laser-hair-reduction">
                          <img
                            src="assets/images/edited-imgs/laser-hair-service.png"
                            width={233}
                            height={233}
                            alt="services"
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="/our-services/laser-hair-reduction">LASER HAIR REDUCTION</a>
                        </h4>
                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                      </div>
                    </div>
                    {/* Services End */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    {/* Services Start */}
                    <div className="single-services text-center">
                      <div className="services-image">
                        <a href="/our-services/slimming-inch-loss-treatment">
                          <img
                            src="assets/images/edited-imgs/body-shaping-treatment.png"
                            width={233}
                            height={233}
                            alt="services"
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="/our-services/slimming-inch-loss-treatment">BODY SHAPING</a>
                        </h4>
                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                      </div>
                    </div>
                    {/* Services End */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    {/* Services Start */}
                    <div className="single-services text-center">
                      <div className="services-image">
                        <a href="/our-services/dermal-filler-botox">
                          <img
                            src="assets/images/edited-imgs/anti-ageing-service-sm.png"
                            width={233}
                            height={233}
                            alt="services"
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="/our-services/dermal-filler-botox">ANTI-AGING TREATMENTS</a>
                        </h4>
                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                      </div>
                    </div>
                    {/* Services End */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    {/* Services Start */}
                    <div className="single-services text-center">
                      <div className="services-image">
                        <a href="/our-services/hairfall-treatment">
                          <img
                            src="assets/images/edited-imgs/hair-loss-service-sm.png"
                            width={233}
                            height={233}
                            alt="services"
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <h4 className="title">
                          <a href="/our-services/hairfall-treatment">HAIR LOSS TREATMENTS</a>
                        </h4>
                        {/* <p>Lorem ipsum dolor, sit amet</p> */}
                      </div>
                    </div>
                    {/* Services End */}
                  </div>
                </SwiperSlide>
                <div className="swiper-pagination" />
              </Swiper>
            </div>
          </div>
        </div>
        {/* Services Main Content End */}
        {/* Services Background Start */}
        <div
          className="services-background"
          style={{
            backgroundImage:
              "url(assets/images/edited-imgs/skin-service-bg-2.png)",
          }}
        />
        {/* Services Background End */}
      </div>
      {/* Services Wrapper End */}
    </div>
  );
}

export default ServiceSection;
