import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { TextArea, TextInput } from "../../components/Form/Form";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  return (
    <div>
      <div className="section section-padding-02">
        <div className="container">
          {/* Brand Start */}
          <div className="brand-wrapper brand-active">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {/* Single Brand Start */}
                {/* <div class="swiper-slide single-brand">
    <img
      src="assets/images/brand/brand-1.webp"
      height="107"
      alt="Brand"
    />
  </div> */}
                {/* Single Brand End */}
                {/* Single Brand Start */}
                {/* <div class="swiper-slide single-brand">
    <img
      src="assets/images/brand/brand-2.webp"
      height="107"
      alt="Brand"
    />
  </div> */}
                {/* Single Brand End */}
                {/* Single Brand Start */}
                {/* <div class="swiper-slide single-brand">
    <img
      src="assets/images/brand/brand-3.webp"
      height="107"
      alt="Brand"
    />
  </div> */}
                {/* Single Brand End */}
                {/* Single Brand Start */}
                {/* <div class="swiper-slide single-brand">
    <img
      src="assets/images/brand/brand-4.webp"
      height="107"
      alt="Brand"
    />
  </div> */}
                {/* Single Brand End */}
                {/* Single Brand Start */}
                {/* <div class="swiper-slide single-brand">
    <img
      src="assets/images/brand/brand-5.webp"
      height="107"
      alt="Brand"
    />
  </div> */}
                {/* Single Brand End */}
              </div>
            </div>
          </div>
          {/* Brand End */}
        </div>
      </div>
      <div className="footer-section section">
        {/* Footer Top Start */}
        <div className="footer-top">
          <img
            className="shape-1 movebounce-01"
            src="/assets/images/shape/shape-16.webp"
            width={185}
            height={231}
            alt="Shape"
          />
          <img
            className="shape-2 movebounce-02"
            src="/assets/images/shape/shape-17.webp"
            width={188}
            height={156}
            alt="Shape"
          />
          <img
            className="shape-3 movebounce-01"
            src="/assets/images/shape/shape-18.webp"
            width={503}
            height={287}
            alt="Shape"
          />
          <div className="container">
            {/* Newsletter Wrapper Start */}
            <div
              className="newsletter-wrapper text-center"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <a className="logo" href="#">
                <img
                  src="/assets/images/logo.png"
                  width={172}
                  height={45}
                  alt="Logo"
                />
              </a>
              <h4 className="title">SUBSCRIBE &amp; ENJOY</h4>
              <p>
                Get the latest updates &amp; offers by subscribing to our
                newsletter!
              </p>
              <div className="newsletter-form">
                <form action="#">
                  <input type="text" placeholder="Enter your email here" />
                  <button className="btn btn-white btn-hover-dark">Send</button>
                </form>
              </div>
            </div>
            {/* Newsletter Wrapper End */}
            {/* Footer Widget Wrapper Start */}
            <div className="footer-widget-wrapper">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widgte Start */}
                  <div
                    className="footer-widget"
                    data-aos="fade-up"
                    data-aos-delay={150}
                  >
                    <h4 className="widget-title">Contact Info</h4>
                    {/* Widgte Info Start */}
                    <div className="footer-widget-info">
                      <div className="single-widget-info">
                        <h6 className="title">Galleria Branch Address :</h6>
                        <p>
                          4306, Ground Floor, DLF Phase 4, <br />
                          Adjacent to Galleria market, Gurgaon, Haryana - 122009
                        </p>
                        <p>
                          <strong>Landline </strong>
                          <a href="tel:+911244230749">+91 124 423 0749</a>
                        </p>
                        <p>
                          <strong>Mobile </strong>
                          <a href="tel:8826815442">8826815442</a>
                        </p>
                      </div>
                      <div className="single-widget-info">
                        <h6 className="title">Sector 45 Branch Address :</h6>
                        <p>
                          Plot no. 42, Ground Floor, Sector 45,
                          <br />
                          Near Hotel Palm Bliss, Gurgaon, Haryana - 122003
                        </p>
                        <p>
                          <strong>Landline </strong>
                          <a href="tel:+911244230749">+91 124 423 0749</a>
                        </p>
                        <p>
                          <strong>Mobile </strong>
                          <a href="tel:9810447794">9810447794 </a>
                        </p>
                      </div>
                      {/* <div class="single-widget-info">
          <h6 class="title">Phone</h6>
          <p><a href="tel:+02154785654">+02154 785 654</a></p>
          <p><a href="tel:+98745852147">+98745 852 147</a></p>
        </div> */}
                    </div>
                    {/* Widgte Info End */}
                  </div>
                  {/* Footer Widget End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widgte Start */}
                  <div
                    className="footer-widget"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <h4 className="widget-title">Quick Link</h4>
                    {/* Widgte Link Start */}
                    <div className="footer-widget-link">
                      <ul>
                        <li>
                          <Link to="/about-us">About us</Link>
                        </li>

                        <li>
                          <Link to="/our-services/skin-rejuvenation">
                            Skin Rejuvenation
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/vampire-facial">
                            Vampire Facial
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/hydra-facial">
                            HydraFacial
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/chemical-peels">
                            Chemical Peel
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/photofacial">
                            Photofacial
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/anti-acne-treatment">
                            Anti-Acne Treatment
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/mesotherapy">
                            Mesotherapy
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* Widgte Link End */}
                  </div>
                  {/* Footer Widgte End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widgte Start */}
                  <div
                    className="footer-widget"
                    data-aos="fade-up"
                    data-aos-delay={450}
                  >
                    <h4 className="widget-title">Information</h4>
                    {/* Widgte Link Start */}
                    <div className="footer-widget-link">
                      <ul>
                        <li>
                          <Link to="/our-services/hairfall-treatment">
                            Hair Fall Treatment
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/hair-prp-therapy">
                            Hair PRP Therapy
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/ayurvedic-treatment">
                            Ayurvedic Treatments
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/slimming-inch-loss-treatment">
                            Slimming & Inch Loss
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/cryolipolysis">
                            Cryolipolysis
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/pigmentation-removal">
                            Pigmentation Removal
                          </Link>
                        </li>
                        <li>
                          <Link to="/our-services/laser-hair-reduction">
                            Laser Hair Reduction
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="#">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                          <Link to="#">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="#">Refund Policy</Link>
                        </li> */}
                      </ul>
                    </div>
                    {/* Widgte Link End */}
                  </div>
                  {/* Footer Widgte End */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  {/* Footer Widgte Start */}
                  <div
                    className="footer-widget"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    <h4 className="widget-title">Opening Hour</h4>
                    {/* Widgte Info Start */}
                    <div className="footer-widget-info">
                      <div className="single-widget-info">
                        <h6 className="title">Tuesday to Sunday</h6>
                        <p>10.00 am - 07.00 Pm</p>
                      </div>
                      <div className="single-widget-info">
                        <h6 className="title">Monday </h6>
                        <p>Closed</p>
                      </div>
                      <div className="single-widget-info">
                        <h6 className="title">Web</h6>
                        <p>
                          <a href="mailto:swakaayawellness@gmail.comm">
                            swakaayawellness@gmail.com
                          </a>
                        </p>
                      </div>
                    </div>
                    {/* Widgte Info End */}
                    {/* Widgte Book End */}
                    <div className="footer-widget-book">
                      <h5 className="book-title">Book Now!</h5>
                      <p>
                        <i className="fa fa-phone"></i>
                        <a href="tel:8826815442">+91-88268 15442</a>
                      </p>
                    </div>
                    <div className="single-info">
                      <ul className="social">
                        <li>
                          <a href="tel:8826815442">
                            <i className="fa fa-phone" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/swakaaya">
                            <i className="fa fa-facebook-f" />
                          </a>
                        </li>

                        <li>
                          <a href="https://www.instagram.com/swakaaya.wellness">
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>

                    {/* Widgte Book End */}
                  </div>
                  {/* Footer Widgte End */}
                </div>
              </div>
            </div>
            {/* Footer Widget Wrapper End */}
          </div>
        </div>
        {/* Footer Top End */}
        {/* Footer Copyright Start */}
        <div className="footer-copyright">
          <div className="container">
            {/* Copyright Wrapper Start */}
            <div className="copyright-wrapper flex-row-reverse">
              <div className="payment-method">
                <ul className="payment-icon">
                  <li>
                    <img
                      src="/assets/images/payment-icon/icon-1.webp"
                      height={15}
                      alt="Icon"
                    />
                  </li>
                  <li>
                    <img
                      src="/assets/images/payment-icon/icon-2.webp"
                      height={15}
                      alt="Icon"
                    />
                  </li>
                  <li>
                    <img
                      src="/assets/images/payment-icon/icon-3.webp"
                      height={15}
                      alt="Icon"
                    />
                  </li>
                  <li>
                    <img
                      src="/assets/images/payment-icon/icon-4.webp"
                      height={15}
                      alt="Icon"
                    />
                  </li>
                </ul>
              </div>

              <div className="copyright-text">
                <p>Copyright © 2022 - Swakaaya. All Rights Reserved.</p>
              </div>
            </div>
            {/* Copyright Wrapper End */}
          </div>
        </div>
        {/* Footer Copyright End */}
      </div>
      <button id="backBtn" className="back-to-top">
        <i className="icofont-simple-up" />
      </button>
      {/*Back To End*/}
      {/* popup */}
      <div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 class="title">
                  Request a <span>Call Back</span>
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div class="register-login-form">
                  <div className="form-wrapper">
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        phone: "",
                        message: "",
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required("Required"),
                        email: Yup.string().required("Required"),
                        phone: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        await axios.get(
                          `https://ledsack-api.ledsak.com/api/email-send/swakaaya?name=${values.name}&email=${values.email}&phone=${values.phone}&message=${values.message}`
                        );
                        history.push("/thank-you");
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="single-form">
                              <TextInput
                                name="name"
                                placeholder="Name"
                                type="text"
                              />
                            </div>
                            <div className="single-form">
                              <TextInput
                                name="email"
                                placeholder="Email"
                                type="email"
                              />
                            </div>
                            <div className="single-form">
                              <TextInput
                                name="phone"
                                placeholder="Phone"
                                type="text"
                              />
                            </div>
                            <div className="single-form">
                              <TextArea
                                name="message"
                                type="text"
                                rows="1"
                                cols="100"
                                placeholder="Message"
                              />
                            </div>
                            <div className="single-form">
                              <button
                                type="submit"
                                className="btn btn-primary btn-hover-dark w-100"
                              >
                                BOOK AN APPOINTMENT
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whatsapp-footer">
        <div className="whatsapp-fix">
          <a href="https://wa.me/918826815442?text=Hi ">
            <img src="/assets/whatsapp.png" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
