import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function PhotoFacial() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Photo Facial"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>PHOTOFACIAL</strong>
                  </h2>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Having flawless skin texture, appearance, and looks is
                      something everyone strives for. However, it’s not easy to
                      retain the skin’s beauty and charm and escape the wrath of
                      blemishes, wrinkles, and fine lines with aging skin.
                      Before we dig into the reasons why Photo Facial is a good
                      choice, let’s look into the basics. For starters, Photo
                      Facial is used to improve the appearance of sun-damaged
                      skin, as well as remove brown and red spots, resulting in
                      the most youthful-looking skin.
                    </span>
                  </p>

                  <p>
                    <strong>PHOTOFACIAL AND ITS TYPES:</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Photo Facial is a quick treatment that is often considered
                      an advanced form of regular facials. It uses different
                      light-emitting devices to treat skin issues.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>TYPES-</strong>
                  </p>
                  <ul>
                    <li aria-level={1}>
                      <strong>IPL Photo Facial</strong>
                    </li>
                  </ul>
                  <ul>
                    <li aria-level={1}>
                      <strong>LED Photo Facial</strong>
                    </li>
                  </ul>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      In IPL Photo Facial, light wavelengths are used to treat
                      pigmentation, brown spots, wrinkles, and spider veins. IPL
                      treatment consistently and predictably improves the
                      texture, tone, and color of the skin with little to no
                      downtime. Any area of the body such as the chest, hands,
                      arms, and legs can be treated with IPL. Photorejuvenation,
                      photo facials, and facial rejuvenation are all terms used
                      to describe IPL therapy.
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Another type of Photo Facial is the LED Photo Facial,
                      which is used for minor skin issues using Light Emitted
                      Diodes. For issues like wrinkles, acne marks, or minor
                      blemishes, these lights are the best option since they
                      only affect the outer layer of the epidermis.
                    </span>
                  </p>

                  <p>
                    <strong>
                      WHAT TYPE OF SKIN PROBLEM CAN A PHOTOFACIAL TREAT?&nbsp;
                    </strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Following skin problems can be treated with the help of
                      PhotoFacial using IPL therapy:
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>Acne</span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>Skin redness</span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>Pigmentation</span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Broken Capillaries
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>Sunspots</span>
                    </li>
                  </ul>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Moreover, photo facial treatments boost collagen
                      production which, in turn, improves skin texture and
                      treats minor dermal injuries.
                    </span>
                  </p>

                  <p>
                    <strong>HOW DOES IT WORK?</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      It works by targeting chromophores in the skin. So,
                      chromophores are different things on the skin, like
                      pigmentation in our freckles and brown spots, blood inside
                      of a blood vessel, and also collagens to stimulate new
                      collagen. So, this results in tightening as well as a
                      reduction in the number of blemishes on our skin.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>WHY INTENSE PULSE TECHNOLOGY (IPL)?</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      IPL has no downtime and it also leads to perfect
                      skin.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>
                      WHAT ARE THE ADVANTAGES OF PHOTO FACIAL TREATMENT?
                    </strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The skin therapy procedure can clear out the skin without
                      causing downtime for busy people with skin problems such
                      as acne, enlarged pores, wrinkles, suntans, and discolored
                      and blotchy skin. A photo facial is a non-invasive, less
                      painful, and convenient way to restore skin health.
                    </span>
                  </p>

                  <p>
                    <strong>
                      HOW MANY SITTINGS ARE REQUIRED TO SEE AN EFFECTIVE RESULT?
                    </strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      A combination of 3 to 5 sessions is necessary for
                      effective results. The patient may also require
                      maintenance treatment once or twice a year.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default PhotoFacial;
