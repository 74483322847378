import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function FaceliftByHifu() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"FACELIFT BY HIFU"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>FACELIFT BY HIFU</strong>
                    </h2>

                    <div>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          High-Intensity Focused Ultrasound (HIFU), is an
                          advanced cosmetic technology that is used to tighten
                          and lift several areas of the face. HIFU Facelift is a
                          non-invasive, non-surgical, and less painful treatment
                          for skin tightening that has replaced the traditional
                          facelift treatment. Ultrasound energy is used to
                          encourage the production of collagen, which results in
                          firmer skin.
                        </span>
                      </p>
                      <p>
                        <strong>BENEFITS OF HIFU FACELIFT TREATMENT:</strong>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Reduces wrinkles
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Tightens sagging skin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Smooths the skin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Enhances the jawline definition
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Reduces double chin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Reduces dark circles, under-eyes, and eye-bags
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Lifts eyebrows, cheeks, neck, and eyelids
                          </span>
                        </li>
                      </ul>
                      <p></p>
                      <p>
                        <strong>HOW DOES IT WORK?</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In this treatment, ultrasound energy or laser beams
                          are used to trigger the production of natural collagen
                          in the body. After that, the collagen makes the tissue
                          around the face tighter and suppler. The reason that
                          makes this treatment popular is that it leverages the
                          body’s natural resources, which means there’s no need
                          for surgery and no need for healing or recovery.
                          Furthermore, it is a natural procedure that makes the
                          patient look like the tightened version of themselves.
                        </span>
                      </p>
                      <p>
                        <strong>HIFU POST-TREATMENT CARE:</strong>
                      </p>
                      <ul>
                        <li aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Make sure not to wash your face with hot water for
                            at least 24 hours.
                          </span>
                        </li>
                      </ul>
                      <ul>
                        <ul>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              Apply moisturizing and regeneration cream on the
                              treated areas.
                            </span>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Avoid scratching your face.
                          </span>
                        </li>
                      </ul>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Avoid excess sun exposure.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Use sun protection creams.
                          </span>
                        </li>
                      </ul>
                      <p></p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          If you’re in search of a treatment that is painless,
                          quick, non-surgical, and non-invasive, then, HIFU
                          Facelift is an excellent option.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default FaceliftByHifu;
