import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function LipEnhancement() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Lip Enhancement"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>LIP ENHANCEMENT</strong>
                    </h2>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Lip enhancement is a cosmetic procedure that can
                        increase the volume of our lips and give us a perfect
                        facial appearance. This procedure can change the
                        structure of our lips and make our lips match our facial
                        expressions by using hyaluronic acid, which is naturally
                        available in our bodies. This treatment uses injectable
                        dermal filler which contains a substance similar to
                        hyaluronic acid. Using these fillers, lip enhancement
                        techniques are quickly performed. Before injecting, the
                        area of the lips is numbed by a numbing cream. Later,
                        the substance gets injected into the lips with a fine
                        needle. Lip volume decreases as we grow, which leads to
                        wrinkled, shriveled, and dry lips in comparison to plump
                        lips at a younger age. This is due to the scarcity of
                        hyaluronic acid and collagen in the body. These fillers
                        help in addressing the scarcity of the substance.
                      </span>
                    </p>

                    <p>
                      <strong>WHY CONSIDER LIP ENHANCEMENT TREATMENT?</strong>
                    </p>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        If you’re bothered by the appearance of your thin,
                        asymmetrical lips and don’t feel confident while
                        smiling, then DON’T PANIC!&nbsp;
                      </span>
                    </p>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Get the perfect, fuller lips that you desire with lip
                        enhancement treatment. It is an aesthetic procedure
                        designed to improve the shape, size, and appearance of
                        the lips, therefore revitalizing the lips. It involves
                        little downtime and provides natural-looking results
                        after a couple of sessions, along with a boost in
                        self-confidence.&nbsp;
                      </span>
                    </p>

                    <p>
                      <strong>LIP FILLERS</strong>
                    </p>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        It is an excellent way for plumping the lips and adding
                        extra volume to them by injecting hyaluronic acid into
                        the lips. It delivers natural-looking and quick results
                        (within 48 hours).
                      </span>
                    </p>

                    <p>
                      <strong>BENEFITS OF LIP FILLERS:</strong>
                    </p>
                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Lip fillers can add structure and a border.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Help achieve a more youthful appearance.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Create more symmetry.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Enhance fullness and volume.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <strong>
                        Get the perfect, fuller lips that you desire with lip
                        enhancement treatment at Swaakaya Wellness Clinic!
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default LipEnhancement;
