import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function HydraFacial() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Hydrafacial"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>HYDRAFACIAL</strong>
                  </h2>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      HydraFacial is one of the most popular treatments for skin
                      performed worldwide. It is a hydro dermabrasion treatment
                      that is very customizable but carries 3 distinct steps:
                    </span>
                  </p>

                  <p>
                    <strong>Step 1</strong>
                    <span style={{ fontWeight: 400 }}>: Cleanse&nbsp;</span>
                  </p>
                  <p>
                    <strong>Step 2</strong>
                    <span style={{ fontWeight: 400 }}>: Exfoliating</span>
                  </p>
                  <p>
                    <strong>Step 3</strong>
                    <span style={{ fontWeight: 400 }}>: Treatment serums</span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      HydraFacial is a non-surgical, non-invasive skin treatment
                      that cleanses, exfoliates, peels, extracts, and hydrates
                      skin all at once. A HydraFacial combines all three
                      treatments into one session, so people no longer have to
                      undergo multiple treatments.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Hydro dermabrasion uses very gentle vacuuming and
                      suctioning to clear out your pores while simultaneously
                      pushing active skincare ingredients like exfoliating acids
                      into the skin.
                    </span>
                  </p>

                  <p>
                    <strong>BENEFITS OF HYDRAFACIAL:</strong>
                  </p>
                  <ul>
                    <li>
                      <span style={{ fontWeight: 400 }}>Cleanses the skin</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span style={{ fontWeight: 400 }}>
                        Removes debris from pores
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span style={{ fontWeight: 400 }}>
                        Treats enlarged and congested pores
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span style={{ fontWeight: 400 }}>
                        Helps even skin tone and better skin texture
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>HOW DOES IT WORK?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      &nbsp;HydraFacial works by exfoliating the top layer of
                      your skin, but at the same time, it’ll push targeted
                      serums into your skin, making it a very customizable
                      treatment.
                    </span>
                  </p>

                  <p>
                    <strong>WHO CAN OPT FOR HYDRAFACIAL?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      &nbsp;HydraFacial is a good option for anyone who wants to
                      deep cleanse their skin or get rid of wrinkles, brown
                      spots, acne, oily skin, or enlarged pores.
                    </span>
                  </p>

                  <p>
                    <strong>HOW LONG BEFORE WILL I SEE THE RESULTS?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The amazing thing about HydraFacial is you will have
                      instantaneous results and they’ll last up to a week and
                      progressive results with continued treatment.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>RECOVERY TIME</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      HydraFacial treatment usually lasts 30-40 mins only, and
                      one can go back home or work after that, as there’s no
                      post-treatment recovery time.
                    </span>
                  </p>

                  <p>
                    <strong>PRE-TREATMENT INSTRUCTION:</strong>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Avoid excess sun exposure.&nbsp;
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Avoid using any type of exfoliant 2 days before
                        treatment.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Don’t wax or use any of the depilatories.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Don’t shave your face on the same day as the treatment.
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>POST-TREATMENT INSTRUCTION:</strong>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Use the post-care regimen prescribed by your
                        dermatologist.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Don’t apply makeup for at least a day.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Avoid direct sun exposure.
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>
                      HOW OFTEN WILL I NEED TO RECEIVE HYDRAFACIAL TREATMENT?
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      For best results, dermatologists recommend HydraFacial
                      treatments once a month.&nbsp;
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If you are interested in learning more about HydraFacial
                      treatment, then you can contact us!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default HydraFacial;
