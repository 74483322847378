import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function HairFallTreatment() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"HAIR FALL TREATMENT"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>HAIR FALL TREATMENT</strong>
                    </h2>

                    <div>
                      <div>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            Hair loss is normal if it occurs over a long period,
                            but it may be distressing if it occurs suddenly.
                          </span>
                          <span style={{ fontWeight: 400 }}>
                            Effective treatment starts with finding the cause.
                            Hair fall doesn’t have a single cause, the reasons
                            vary from person to person.&nbsp;
                          </span>
                        </p>
                        <p>
                          <strong>
                            TREATMENTS FOR HAIR LOSS INCLUDE:&nbsp;
                          </strong>
                        </p>
                        <ul>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              Hair PRP Therapy
                            </span>
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>Mesotherapy</span>
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              Hair Transplant
                            </span>
                          </li>
                        </ul>

                        <ol>
                          <li>
                            <strong> HAIR PRP THERAPY</strong>
                            <span style={{ fontWeight: 400 }}>
                              : Platelet Rich Plasma or PRP Hair Therapy uses a
                              patient’s own blood to promote the healing of hair
                              loss. PRP is taking over the medical field because
                              this is the most natural procedure and your own
                              growth factors that are going to work for your
                              hair growth.
                            </span>
                          </li>
                        </ol>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            It is a revolutionary process that is effective in
                            treating hair loss and makes the problem areas
                            thicker and denser that are being treated.
                          </span>
                        </p>
                        <p>
                          <strong>
                            THE HAIR PRP THERAPY PROCEDURE INCLUDES:
                          </strong>
                        </p>
                        <ul>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              The procedure is performed under local anesthesia.
                              Hence, the procedure is painless.
                            </span>
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              The blood is extracted from the vein in the arm
                              (approx. 30 ml) and placed in a device called a
                              centrifuge machine for around eight to ten minutes
                              to get the plasma separated from the rest of the
                              blood.
                            </span>
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              The plasma that was separated is injected all over
                              the scalp using a thin needle syringe or
                              microneedle.&nbsp;
                            </span>
                          </li>
                          <li style={{ fontWeight: 400 }} aria-level={1}>
                            <span style={{ fontWeight: 400 }}>
                              The entire process takes around 45 minutes to one
                              hour.
                            </span>
                          </li>
                        </ul>

                        <ol start={2}>
                          <li>
                            <strong> MESOTHERAPY: </strong>
                            <span style={{ fontWeight: 400 }}>
                              Mesotherapy is a non-invasive and non-surgical
                              cosmetic hair restoration treatment that has been
                              steadily gaining in popularity.&nbsp;
                            </span>
                          </li>
                        </ol>
                        <br></br>

                        <p>
                          <strong>HOW DOES IT WORK?</strong>
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            In this procedure, superficial micro-injections are
                            inserted just below the epidermal layer of the skin
                            into the appropriate tissues to stimulate the
                            mesoderm layer in the skin which lies at a depth of
                            about one millimeter beneath the surface to
                            encourage the hair’s natural regeneration and
                            proliferation process. The technology at the root of
                            the process is based on the understanding that most
                            hair loss problems are the result of a lack of
                            proper nutrients, reduced blood circulation to the
                            scalp, and hormone imbalances. In particular, excess
                            production of hair loss androgen DHT for hair
                            restoration treatments.
                          </span>
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            A lot of people, these days, blow-dry and color
                            their hair and later complain about limp and brittle
                            hair which breaks the hair in between and makes the
                            shaft very weak. Sometimes they even have pure
                            thinning of hair, it is not hair loss, it is just
                            that hair seems to have thinned out over time. So,
                            in such cases, mesotherapy comes to the rescue. We
                            electroporate meso solution into the scalp with the
                            help of a derma roller and laser therapy. The
                            procedure is quite simple and relaxing and by the 3
                          </span>
                          <span style={{ fontWeight: 400 }}>rd</span>
                          <span style={{ fontWeight: 400 }}> to 4</span>
                          <span style={{ fontWeight: 400 }}>th</span>
                          <span style={{ fontWeight: 400 }}>
                            session, the patient starts to see improvement. For
                            people who are fond of styling their hair once or
                            twice a week, this is a great option to maintain
                            hair health. This treatment has no side effects as
                            we use tried and tested sterilized solutions and we
                            don’t inject anything into the scalp. So, the result
                            is a happy patient.&nbsp;
                          </span>
                        </p>

                        <ol start={3}>
                          <li>
                            <strong> HAIR TRANSPLANT: </strong>
                            <span style={{ fontWeight: 400 }}>
                              A Hair Transplant is a procedure or surgery in
                              which the surgeon moves hair. The surgeon will
                              have to fill an area with thin or no hair.
                              Usually, the surgeon transplants hair from the
                              occipital area(back) and implants it in the
                              front.&nbsp;
                            </span>
                          </li>
                        </ol>
                        <br></br>

                        <p>
                          <strong>WHO REQUIRES HAIR TRANSPLANTATION?</strong>
                        </p>
                        <p>
                          <span style={{ fontWeight: 400 }}>
                            There are 8 grades of baldness and once we enter
                            into grades 6,7 and 8, we require hair
                            transplantation. Before that, there are some
                            medications like minoxidil, finasteride, capixyl,
                            and processes like PRP, which will help in retaining
                            the hair and make it strong. However, these
                            medications can’t regrow hair; once we are bald,
                            we’re bald. This is genetic, hormonal, and
                            age-related. So, taking hair from the back and
                            implanting it in the front is the only option to get
                            rid of baldness. The implanted hair is permanent and
                            natural so we can color it and comb it too.&nbsp;
                          </span>
                        </p>

                        <p>
                          <strong>
                            To know more about these treatments, contact us!
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default HairFallTreatment;
