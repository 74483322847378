import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function SkinRejuvenation() {
  return (
    <div>
      <Header />
      <Breadcrumb title={" Skinrejuvenation"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong> Skin Rejuvenation</strong>
                    </h2>
                    <div>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Nobody wants to look older than their current age.
                          Everyone wants a smooth, clear and even skin &amp;
                          texture, but the biggest question remains the same,
                          that is how to maintain the beauty and charm of the
                          skin.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>Our experts at </span>
                        <strong>Swaakaya Wellness Clinic</strong>
                        <span style={{ fontWeight: 400 }}>
                          perform various skin, cosmetic surgical and
                          non-surgical treatments, which you can opt for having
                          beautiful and rejuvenating skin.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Laser toning is a procedure that is done to bring back
                          the smoothness of the skin and to get rid of the
                          tanning on the face. It is helpful in rejuvenating and
                          revitalizing the skin. The procedure works in
                          stimulating the production of collagen, which
                          generates new collagen and is effective in decreasing
                          the lines and wrinkles. Skin Rejuvenation treatments
                          are helpful in solving the problem of discoloration,
                          pigmentation, redness, and large pores on the skin. It
                          gives a refreshed appearance, a smooth skin texture,
                          an even skin tone, and also reduces the pore size of
                          the skin.
                        </span>
                      </p>

                      <p>
                        <strong>
                          SKIN REJUVENATION CAN BE PERFORMED UNDER THE FOLLOWING
                          CONDITIONS:
                        </strong>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Sun-damaged skin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Undesired skin complexion
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Fine lines and wrinkles
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Irregular skin texture
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Acne scarring</span>
                        </li>
                      </ul>

                      <p>
                        <strong>
                          VARIOUS SKIN REJUVENATION TREATMENTS INCLUDE:
                        </strong>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Microdermabrasion
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Chemical Peeling
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Face PPR Therapy
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Laser Facial</span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Facial Fillers
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Botox</span>
                        </li>
                      </ul>
                      <p></p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The face is the most crucial feature of the human
                          body. With time, it can get damaged by external and
                          internal elements such as ageing, the sun, hormonal
                          imbalances, acne etc. There are various non-surgical
                          facial rejuvenation services and treatments available
                          at our clinic for repair of the damaged skin and
                          making you look revitalized.
                        </span>
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default SkinRejuvenation;
