import React from "react";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function About() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"About us"} />

      <div className="section section-padding about-section">
        <div className="container">
          {/* About Wrapper Start */}
          <div className="about-wrapper">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-8">
                {/* About Image Start */}
                <div
                  className="about-image"
                  data-aos="fade-right"
                  data-aos-delay={200}
                >
                  <img
                    src="/assets/images/about.jpg"
                    width={540}
                    height={565}
                    alt="About"
                  />
                </div>
                {/* About Image End */}
              </div>
              <div className="col-lg-6">
                {/* About Image Start */}
                <div
                  className="about-content"
                  data-aos="fade-left"
                  data-aos-delay={200}
                >
                  {/* Section Title Start */}
                  <div className="section-title">
                    <h2 className="">Welcome to Swakaaya Clinic</h2>
                  </div>
                  {/* Section Title End */}

                  <p>
                    <strong>Swakaaya Wellness Clinic</strong> is based in Delhi
                    & Gurugram and has been founded by Dr. Neha Thukral in the
                    year 2019 with the intention of providing quality treatment
                    for all types of skin, hair, and cosmetic concerns. Our
                    flagship services include Hair restoration, Skincare, Body
                    shaping, Anti-aging, and Aesthetic surgery.
                  </p>
                  <p>
                    Our team understands how distressing hair loss, acne, and
                    belly fat can be. Whether patients are hoping to have
                    cosmetic treatment or to treat a condition, our experts will
                    provide them with the best possible treatment available.
                    Clients of all ages are welcome at the Swakaaya Wellness
                    Clinic for skin, hair & body care. We tailor our services to
                    every customer, considering their skin type, budget, and
                    requirements.
                  </p>
                  <p>
                    With thousands of clients in our database, we are committed
                    to delivering the highest quality care by using the most
                    advanced methods and technologies in the research,
                    diagnosis, and treatment of skin disorders.
                  </p>
                  <div className="proud">
                    <h5>
                      WE ARE PROUD TO HAVE RENOWNED DERMATOLOGISTS AND COSMETIC
                      SURGEONS WITH 15 YEARS OF EXPERIENCE.
                    </h5>
                  </div>
                </div>
                {/* About Image End */}
              </div>
            </div>
          </div>
          {/* About Wrapper End */}
        </div>
      </div>
      <section className="section section-padding about-section ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="doctor-iamge">
                <img src="/assets/images/swakaaya-founder.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="doctor-content">
                <h2> Dr. Neha Thukral, Founder </h2>
                <p>
                  The founder and Director of Swakaaya Wellness clinic is a renowned facial aesthetician who has been practicing for over a decade. She has a profound experience of 14 Years in the field of health & Wellness.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5">
              <div className="doctor-iamge">
                <img src="/assets/images/diet_3.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="doctor-content">
                <h2> Dr. Akshara Shaji</h2>
                <p><strong>(Medical Aesthetician and cosmetologist )</strong></p>
                <p>
                  Dr. Shaji is an internationally certified expert cosmetologist and facial aesthetician , she understands the problems faced by our new gen Z clients and their need to be updated with the latest beauty trends , as well as having greater knowledge ,for what works best for mature skin ,in regards to the latest advancements in aesthetic industry . Dr. Akshara strives towards delivering the perfection in every thing she does , no wonder why the patient satisfaction level with her has been really high . A warm nature and vast knowledge in her field ,she is an expert in doing all advanced facial injectables and Clinical Cosmetology procedures with minimal discomfort . Apart from this , she is also a permanent makeup specialist (certified from American Academy of Micropigmentation), who has provided the clients ,with some of the most beautiful and natural looking eyebrow and lips transformation.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-5">
              <div className="doctor-iamge">
                <img src="/assets/images/diet_2.png" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="doctor-content">
                <h2> Ms. Shalini Kumar, Dietician</h2>
                <p>
                  She is a certified nutritionist and has 15 years of working experience in India & overseas. Her areas of expertise are weight loss, Diabetes Management, PCOD, Hypothyroidism etc.
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* <section className="section section-padding about-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="doctor-content">
                <h2>MEET OUR CO-FOUNDER: DR. RISHI KAPAHI</h2>
                <p>
                  <strong>Dr. Rishi Kapahi,</strong> co-founder of Swakaaya
                  Wellness Clinic and a renowned doctor in New Delhi. Dr. Kapahi
                  enjoys working closely with his patients to create
                  personalized treatment plans aimed to improve, repair, and
                  maintain healthy glowing skin.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="doctor-iamge">
                <img src="" />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="help">
                <h4 className="text-center" style={{ fontWeight: 700 }}>
                  We help you to look and feel beautiful. Our motive is to make
                  you feel confident in your body. We are just a call away!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
}

export default About;
