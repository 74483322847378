import React from "react";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div className="section section-padding">
      <div className="container">
        {/* Features Wrapper Start */}
        <div className="features-wrapper row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
          <div className="col" data-aos="fade-up" data-aos-delay={200}>
            {/* Single Features Start */}
            <div className="single-feature text-center">
              {/* <img src="/assets/images/icon/Slimming.png" /> */}
              <img
                src="assets/images/icon/Laser_hair_Reduction.png"
                alt="feature"
              />
              <h4 className="title">
                <Link to="/our-services/laser-hair-reduction">
                  LASER HAIR REDUCTION
                </Link>
              </h4>
              <p>
                Get permanent full-body laser hair reduction at our clinic. It
                is a pain-free procedure and is performed under the expertise of
                skilled dermatologists.
              </p>
              <Link to="/our-services/laser-hair-reduction" className="more">
                Read more
              </Link>
            </div>
            {/* Single Features End */}
          </div>
          <div className="col" data-aos="fade-up" data-aos-delay={600}>
            {/* Single Features Start */}
            <div className="single-feature text-center">
              <img src="assets/images/icon/Anti_Ageing.png" alt="feature" />
              <h4 className="title">
                <Link to="/our-services/dermal-filler-botox">
                  ANTI-AGING TREATMENTS
                </Link>
              </h4>
              <p>
                We offer anti-aging treatments that are effective in restoring
                youthful skin. We have both surgical &amp; non-surgical
                treatments that can help combat aging.
              </p>
              <Link to="/our-services/dermal-filler-botox" className="more">
                Read more
              </Link>
            </div>
            {/* Single Features End */}
          </div>
          <div className="col" data-aos="fade-up" data-aos-delay={600}>
            {/* Single Features Start */}
            <div className="single-feature text-center">
              <img src="assets/images/icon/hair-loss.png" alt="feature" />
              <h4 className="title">
                <Link to="/our-services/hairfall-treatment">
                  HAIR LOSS TREATMENTS
                </Link>
              </h4>
              <p>
                Get a natural hair look with our hair-loss treatments. We offer
                customized advanced treatments depending on the type of hair
                loss, baldness pattern, etc.
              </p>
              <Link to="/our-services/hairfall-treatment" className="more">
                Read more
              </Link>
            </div>
            {/* Single Features End */}
          </div>
          <div className="col " data-aos="fade-up" data-aos-delay={400}>
            {/* Single Features Start */}
            <div className="single-feature text-center active">
              <img src="assets/images/icon/Slimming.png" alt="feature" />
              <h4 className="title">
                <Link to="/our-services/slimming-inch-loss-treatment">
                  MOMMY MAKEOVER
                </Link>
              </h4>
              <p>
                Our Mommy Makeover treatments are non-surgical procedures that
                safely remove stubborn fat deposits from the body.
              </p>
              <Link
                to="/our-services/slimming-inch-loss-treatment"
                className="more"
              >
                Read more
              </Link>
            </div>
            {/* Single Features End */}
          </div>
        </div>
        {/* Features Wrapper End */}
      </div>
      <div></div>
    </div>
  );
}

export default Services;
