import React from "react";

function WhyChooseUs() {
  return (
    <div className="section section-padding mb-5">
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title text-center">
          {/* <h6 class="sub-title">Good For Health</h6> */}
          <h2 className="title">WHY CHOOSE US?</h2>
          <p>
            With several years of experience in the field of both clinical and
            aesthetic dermatology along with thorough training under
            internationally renowned faculty, our experts have acquired skills
            which are at par with international standards.
          </p>
        </div>
        {/* Section Title End */}
        {/* Choose Wrapper Start */}
        <div className="choose-wrapper">
          <div className="row justify-content-center align-items-center gx-lg-0">
            <div className="col-lg-3 col-sm-6 order-2 order-lg-1">
              {/* Single Choose Start */}
              <div
                className="single-choose"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <h4 className="title">• Latest &amp; Advanced Technology</h4>
                {/* <p>
              Message Therapy is the best way of Skin Treatment cases are perfectly
              simple and easy
            </p> */}
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}
              <div
                className="single-choose"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <h4 className="title">
                  • Experienced Dermatologists &amp; Skin Therapists
                </h4>
                {/* <p>
              Message Therapy is the best way of Skin Treatment cases are perfectly
              simple and easy
            </p> */}
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}
              <div
                className="single-choose"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h4 className="title">• 100% Customer Satisfaction</h4>
                {/* <p>
              Message Therapy is the best way of Skin Treatment cases are perfectly
              simple and easy
            </p> */}
              </div>
              {/* Single Choose End */}
            </div>
            <div className="col-lg-6 order-1 col-md-8 order-lg-2">
              {/* Choose Image Start */}
              <div
                className="choose-image"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="image">
                  <img src="/assets/images/about/02.png" alt="Choose" />
                </div>
              </div>
              {/* Choose Image End */}
            </div>
            <div className="col-lg-3 col-sm-6 order-3 order-lg-3">
              {/* Single Choose Start */}
              <div
                className="single-choose"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <h4 className="title">
                  • Personalized Skin/Hair/Body Treatments
                </h4>
                {/* <p>
              Message Therapy is the best way of Skin Treatment cases are perfectly
              simple and easy
            </p> */}
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}
              <div
                className="single-choose"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <h4 className="title">• Thousands of Happy Clients</h4>
                {/* <p>
              Message Therapy is the best way of Skin Treatment cases are perfectly
              simple and easy
            </p> */}
              </div>
              {/* Single Choose End */}
              {/* Single Choose Start */}
              <div
                className="single-choose"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h4 className="title">• US-FDA Approved Technology</h4>
                {/* <p>
              Message Therapy is the best way of Skin Treatment cases are perfectly
              simple and easy
            </p> */}
              </div>
              {/* Single Choose End */}
            </div>
          </div>
        </div>
        {/* Choose Wrapper End */}
      </div>
    </div>
  );
}

export default WhyChooseUs;
