import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Breadcrumb from "../common/Breadcrumb";

const PageNotFound = () => {
  return (
    <>
      <div className="main-wrapper">
        {/* Header Start */}
        <Header />
        <Breadcrumb title={"Page Not Found"} />
        <section className="section section-padding-02 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="service-content">
                  <h2>
                    <strong>Opps! PAGE NOT BE FOUND</strong>
                  </h2>
                  <p>
                    Sorry but the page you are looking for does not exist, have
                    been removed, name changed or is temporarily unavailable.
                  </p>
                  <p>
                    <Link class="btn btn-primary btn-hover-dark" to="/">
                      Go to Home
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
