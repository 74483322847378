import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function DermalFillersBotox() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Dermal Fillers & Botox"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>Dermal Fillers & Botox</strong>
                    </h2>

                    <div>
                      <p>
                        <span style={{ fontWeight: 400 }}>We at </span>
                        <strong>Swaakaya Wellness Clinic</strong>
                        <span style={{ fontWeight: 400 }}>
                          offer injectable treatments such as Botox and dermal
                          fillers that can be done alone or in combination and
                          help in minimizing the appearance of fine lines and
                          wrinkles.
                        </span>
                      </p>
                      <p>
                        <strong>Dermal fillers</strong>
                        <span style={{ fontWeight: 400 }}>
                          are made of natural or synthetic materials and are
                          helpful in the reduction of facial wrinkles and fine
                          lines by restoring lost facial volume, plumping up the
                          tissue. The best option for fillers depends upon your
                          personal needs, age, goals, and lifestyle.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          On the other hand,{" "}
                        </span>
                        <strong>Botox </strong>
                        <span style={{ fontWeight: 400 }}>
                          typically enhances your appearance in 48 hours, with
                          the maximum effect reached in two weeks. The results
                          of Botox typically last three to four months and, with
                          repeated treatments, may last longer.
                        </span>
                      </p>

                      <p>
                        <strong>WHO IS A RIGHT CANDIDATE?</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Dermal fillers &amp; Botox are a non-invasive
                          procedure for clients who are looking to plump up
                          sagging skin and smooth out wrinkles for a younger
                          appearance but who are scared of surgery.
                        </span>
                      </p>

                      <p>
                        <strong>DERMAL FILLERS CAN HELP PATIENTS:</strong>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Obtain fuller lips.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Enhance their natural lip outline and definition
                            that decreases with aging.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Plumping of deep wrinkles and nasolabial folds.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Fill in facial depressions, such as those from acne.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Options for the use of fillers decrease with age.
                          </span>
                        </li>
                      </ul>
                      <p>
                        <strong>WHAT TO EXPECT DURING THE PROCEDURE?</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Fillers are injected with the help of small needles
                          into lips, wrinkles or facial folds such as "smile
                          lines." The entire procedure hardly takes only
                          minutes.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Clients typically are able to return to work the next
                          day. Rarely, some clients experience more significant
                          swelling that takes a couple of days to subside.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default DermalFillersBotox;
