import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function Mesotherapy() {
  return (
    <div>
      <div>
        <Header />
        <Breadcrumb title={"Mesotherapy"} />
        <section className="section section-padding-02 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="service-content">
                  <div>
                    <div>
                      <h2>
                        <strong>MESOTHERAPY</strong>
                      </h2>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The desire to look good and keep our skin glowing and
                          resist aging is strong in many of us. The mesotherapy
                          procedure is one of the easiest skin rejuvenation
                          procedures. It means injecting or delivering products
                          into the dermal layer of the skin. Our skin has a
                          superficial layer called the epidermis and a layer
                          called the dermis below that and when we deliver
                          products into the dermis it’s called mesotherapy. In
                          the case of creams and pills, they only penetrate the
                          epidermis; they can't reach the dermis. It helps these
                          products (hyaluronic acid, vitamin c, glutathione,
                          etc.) to enter our dermis layer.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The procedure usually involves small injections which
                          form small blebs in the skin. These blebs will
                          disappear over a period of one to two days. During
                          this time, one can expect:
                        </span>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Redness (erythema)
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Scratching the skin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Itching</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Touch sensitivity
                          </span>
                        </li>
                      </ul>

                      <p>
                        <strong>WHAT ARE THE METHODS OF MESOTHERAPY?</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Mesotherapy can be performed by two methods:
                        </span>
                      </p>
                      <ul>
                        <ul>
                          <li style={{ fontWeight: 400 }}>
                            <strong>Invasive:</strong>
                            <span style={{ fontWeight: 400 }}>
                              In the invasive method, the doctor injects the
                              drug directly into the skin or uses a derma pen or
                              a derma roller.
                            </span>
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li>
                          <strong>Non-invasive: </strong>
                          <span style={{ fontWeight: 400 }}>The </span>
                          <strong>non-invasive method</strong>
                          <span style={{ fontWeight: 400 }}>
                            is performed by electroporation.
                          </span>
                        </li>
                      </ul>

                      <p>
                        <strong>
                          WHAT ARE THE INDICATIONS OF MESOTHERAPY?
                        </strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Mesotherapy can be used on the skin, it can be used on
                          the scalp. When we say it can be used on the skin, it
                          is used for skin rejuvenation, skin hydration, dull
                          skin, and skin glow as an anti-age procedure, for
                          post-acne scars. And when we use it on the scalp, we
                          will use it in case the patient has a hair fall or he
                          wants to boost hair growth, then Saturn meso solution
                          can be injected directly into the scalp.&nbsp;
                        </span>
                      </p>

                      <p>
                        <strong>WHAT ARE THESE MESO SOLUTIONS</strong>

                        <strong>MADE UP?</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          These Meso solutions can be vitamins, cytokines,
                          antioxidants, and peptides.
                        </span>
                      </p>
                      <p>
                        <strong>Post-mesotherapy Precautions-</strong>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Do not wash your face for the first 12 hours.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Apply moisturizing cream as frequently as possible.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Use sunscreen religiously
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Avoid going to the gym, swimming, and sauna for the
                            first 20 to 48 hours.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Do not schedule laser procedures two weeks after the
                            procedure if you have certain procedures scheduled.
                          </span>
                        </li>
                      </ul>

                      <p>
                        <strong>
                          HOW LONG AFTER THE TREATMENT CAN THE RESULT BE
                          OBSERVED?
                        </strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The result can be seen after the first treatment only.
                          You can expect a long-lasting and significant effect
                          after 4 to 6 sittings. Your skin will continue to
                          improve after the last treatment.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          In a nutshell, it is a very safe procedure to address
                          your skin issues. One might have erythema (redness)
                          which might resolve everything about post-procedure
                          before 24-48 hours.&nbsp;
                        </span>
                      </p>

                      <p>
                        <strong>MESOTHERAPY FOR HAIR</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          It is one of the best treatments for hair loss. This
                          procedure is effective for reducing the thinning of
                          hair, which is one of the main causes of a severe hair
                          fall illness. The procedure is painless and
                          non-surgical. It is done by injecting stem cell serum
                          or platelet rich plasma injections into the scalp.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <WhyChooseUs />
        <Testimonial />
        <Footer />
      </div>
    </div>
  );
}

export default Mesotherapy;
