import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function ThreadLift() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"THREAD LIFT"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>THREAD LIFT</strong>
                    </h2>
                    <div>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The thread lift is an ideal type of facelift for
                          people who are suffering from loose and saggy skin on
                          areas such as their face. This entire process is
                          becoming popular as it is minimally invasive.&nbsp;
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          It is a non-surgical procedure that is done for saggy
                          skin, with the support of local anesthesia. The
                          upliftment of the face is done with the help of
                          Absorbable PDO (polydioxanone) sutures. The sutures
                          are inserted into the skin of the client and are then
                          pulled upwards and tightened. This helps in the upward
                          pulling of loose skin. The procedure activates the
                          natural healing response of our body and makes it an
                          ongoing process that is helpful in maintaining the
                          contours of the face and keeping the skin from
                          becoming loose and saggy again for a long period of
                          time.
                        </span>
                      </p>

                      <p>
                        <strong>WHAT ARE THE BENEFITS OF THREAD LIFT?</strong>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The thread lift process has various benefits over
                          other face lifting surgeries. It utilizes temporary
                          sutures to lift the saggy skin and keep it pulled up,
                          which gives lifting that is noticeable on the
                          face.&nbsp;
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          On the other hand, it induces the natural healing
                          response of the body, which is helpful in leading to
                          more collagen deposits at the site of the sutures.
                        </span>
                      </p>

                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Reduced wrinkles and fine lines and tighter-looking
                            skin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Instant lift</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Long-term effects
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Short duration of the procedure
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>No downtime</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Suitable for people who cannot undergo other surgery
                          </span>
                        </li>
                      </ul>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The recovery time of the procedure is minimal and the
                          client can go to normal life or work after some time.
                          It gives instant lifting results but you may notice
                          the results better after the initial swelling that
                          usually occurs after the process but it subsides in a
                          few days.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          The outcomes of the process last for about 1 to 3
                          years.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default ThreadLift;
