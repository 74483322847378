import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function AyurvedicTreatment() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Ayurvedic Treatment"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <h2>
                  <strong>AYURVEDIC TREATMENTS</strong>
                </h2>
                <div>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Diet and exercise plans become increasingly challenging as
                      we age. The excessive weight may still be ignored by many
                      of us. It should not be treated this way. To our rescue,
                      Ayurveda has come up with some medicines and exercises
                      that help us to get rid of our stubborn fats.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The ayurvedic treatment for weight loss offered by&nbsp;
                    </span>
                    <strong>Swakaaya Wellness Clinic</strong>&nbsp;
                    <span style={{ fontWeight: 400 }}>
                      is a combination of massage, lifestyle changes, and some
                      special Ayurvedic medication. It is a natural treatment
                      and doesn’t include any surgery or a strict diet
                      plan.&nbsp;
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The following are the four-fold approaches recommended by
                      us for weight loss, obesity, and weight control:
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Medication</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Detoxifying Techniques
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Healthy Diet</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Modification of lifestyle
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>DETOXIFICATION WITH THE HELP OF UDVARTANAM:</strong>
                    <span style={{ fontWeight: 400 }}>
                      This therapy uses a mixture of herbal powder and warm oil.
                      By using the mixture, a whole-body massage is given to the
                      patient with special attention.
                    </span>
                  </p>
                  <p>
                    <strong>KASHAYA VASTI: </strong>
                    <span style={{ fontWeight: 400 }}>
                      This is the most effective remedy to treat weight loss. In
                      this treatment, the heat is directly applied to the
                      targeted area by keeping warm medicated oils for
                      retention.&nbsp;
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Ayurvedic ingredients for skin care are well-known for
                      giving positive results. One of the best natural beauty
                      treatments to get a natural glow is Ayurvedic Facial. This
                      treatment includes massaging using some special herbal
                      oils and creams. Apart from these facials, we offer
                      Shaundarya and Shringara.
                    </span>
                  </p>
                  <p>
                    <strong>
                      BENEFITS OF MUKH LEPAM (AYURVEDIC FACIAL)-&nbsp;
                    </strong>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Clears the debris</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Exfoliates the skin
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Provides fresh nutrition to the skin
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Opens clogged pores
                      </span>
                    </li>
                  </ul>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Another facial is there, which is known as &nbsp;
                    </span>
                    <strong>Navara Mukh Lepam</strong>
                    <span style={{ fontWeight: 400 }}>
                      . It acts as a facelift and helps us look and feel
                      younger.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>
                      Feed your skin healthy to keep your skin healthy with
                      Swakaaya Wellness Clinic!
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default AyurvedicTreatment;
