import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
function Testimonial() {
  return (
    <div className="section section-padding testimonial-section">
      {/* <img
class="shape-1 movebounce-03"
src="assets/images/shape/shape-14.webp"
width="212"
height="191"
alt="Shape"
/> */}
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title text-center">
          <h6 className="sub-title">CLIENT TESTIMONALS</h6>
          <h2 className="title">WHAT OUR CLIENTS SAY</h2>
          <p />
        </div>
        {/* Section Title End */}
        {/* Testimonial Wrapper Start */}
        <div
          className="testimonial-wrapper testimonial-active"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="row row-cols-12 row-cols-lg-12">
            <div className="">
              <div className="swiper-container">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  autoplay={1000}
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  slidesPerView={2}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>

                    <div className="swiper-slide single-testimonial">
                      <div className="testimonial-img">
                        <img src="/assets/images/gazal-edited.png" alt="img" />
                      </div>

                      <div className="testimonial-content">
                        <p>
                          Hi I am Gazal Arora. A makeup artist an entrepreneur and an anchor. I have seen lot of changes in my face and skin due to hectic work schedules and lifestyle. My face went extremely dull and pigmented but few months back when i got to Swakaya within couple of months i can see alot difference. They understood my skin and made a genuine series of procedures to get me my beautiful skin back. I am extremely happy being swakaya patient as they have got the knowledge and experience to meet our requirements. I can see me glowing and gaining no makeup confidence back.  Apart from that my laser sessions with them are goong extremely great.
                        </p>
                        <h4 className="name">Gazal Arora</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide single-testimonial">
                      <div className="testimonial-content">
                        <p>
                          I visited
                          <strong> Swakaaya Wellness Clinic</strong> for hair
                          loss treatment, and I’m satisfied with the results, my
                          treatment is still going on. Thank you, team!
                        </p>
                        <h4 className="name">Rahul Kumar</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide swiper-slide single-testimonial">
                      <div className="testimonial-author"></div>
                      <div className="testimonial-content">
                        <p>
                          Have been availing all the services since the last few years. Highly trained and experienced staff who are extremely professional and provide great service. The services be it laser, wellness are world class. After visiting this clinic you will really not feel like going anywhere else....Just love this place
                        </p>
                        <h4 className="name">Janardhan Thalatoti</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide swiper-slide single-testimonial">
                      <div className="testimonial-author"></div>
                      <div className="testimonial-content">
                        <p>
                          Such an amazing service and very budget-friendly consultation. You can blindly trust Dr. Neha with al your skin concerns. All the skin services have been very effective and they offer a wide range of options right from laser to facials depending on the need of the skin.
                        </p>
                        <h4 className="name">Neha Mishra</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide swiper-slide single-testimonial">
                      <div className="testimonial-author"></div>
                      <div className="testimonial-content">
                        <p>
                          Great services and satisfactory results always. Staff is polite and helpful.I have taken full body laser treatment sessions.
                          Good knowledge about work and give the best results.

                          Thanks Dr. NEHA
                        </p>
                        <h4 className="name">Vikas singh</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide swiper-slide single-testimonial">
                      <div className="testimonial-author"></div>
                      <div className="testimonial-content">
                        <p>
                          I started my skin treatment for pigmentation   at Swakaaya wellness clinic and can see the visible  results after 3 sittings.Doctors are good and treat u with utmost care. Thank u team Swakaaya. Going to recommend it to my friends.
                        </p>
                        <h4 className="name">Gazala Beg</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide swiper-slide single-testimonial">
                      <div className="testimonial-author"></div>
                      <div className="testimonial-content">
                        <p>
                          I have visited swakaaya wellness last month for lazer hair removal services and it has faboulous results.
                          Dr.Neha helped me most of my sittings and she is very humble and experienced at her job.
                          Its an amazing experience.

                          Thanks swakaaya !
                        </p>
                        <h4 className="name">kaushal kishor</h4>
                        <div className="rating">
                          <div
                            className="rating-star"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <div className="swiper-button-next" />
                  <div className="swiper-button-prev" />
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {/* Testimonial Wrapper End */}
      </div>
    </div>
  );
}

export default Testimonial;
