import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="section section-padding-02 about-section">
      <img
        className="shape-1 movebounce-03"
        src="assets/images/shape/shape-5.webp"
        width={420}
        height={210}
        alt="Shape"
      />
      <div className="container">
        {/* About Wrapper Start */}
        <div className="about-wrapper">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-8">
              {/* About Image Start */}
              <div
                className="about-image"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <img
                  src="assets/images/about/03.png"
                  width={540}
                  height={565}
                  alt="About"
                />
              </div>
              {/* About Image End */}
            </div>
            <div className="col-lg-6">
              {/* About Image Start */}
              <div
                className="about-content"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                {/* Section Title Start */}
                <div className="section-title">
                  {/* <h6 class="sub-title">Wellcom to Peerly</h6> */}
                  <h2 className="title">
                    GET LIGHTER &amp; <br />
                    BRIGHTER SKIN, EFFORTLESSLY!
                    <img
                      className="shape"
                      src="assets/images/shape/shape-15.webp"
                      alt="Shape"
                    />
                  </h2>
                </div>
                {/* Section Title End */}
                <p className="text">
                  Skincare protection is a small price to pay for healthy skin.
                </p>
                <p>
                  <strong>Swakaaya Wellness Clinic</strong> offers the most
                  advanced solutions for your skin, hair &amp; body. We believe
                  that every person has a unique body &amp; skin type, hence we
                  focus on providing personalized care to every client. Our team
                  is dedicated to bringing your skin to the healthiest it’s ever
                  been.
                </p>
                <Link
                  to="/contact-us"
                  className="btn btn-primary btn-hover-dark"
                >
                  Contact Us
                </Link>
              </div>
              {/* About Image End */}
            </div>
          </div>
        </div>
        {/* About Wrapper End */}
      </div>
    </div>
  );
}

export default About;
