import React from "react";

function Banner() {
  return (
    <section>
      <div className="banner-iamge">
        <img src="./assets/images/banner/banner-new.png" />
      </div>
    </section>
  );
}

export default Banner;
