import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function AntiAcneTreatment() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"AntiAcne Treatment"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <h2>
                  <strong>ANTI-ACNE TREATMENT</strong>
                </h2>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Acne is a skin disease consisting of blemishes that can
                    occur on your face, chest, shoulders, back, and neck. Acne
                    is a very common disease during our teen years but it can
                    still affect us during adulthood as well. To keep your skin
                    and hair well lubricated, your body depends on sebaceous
                    glands which sit right under your skin. An oily substance
                    called sebum gets secreted, which coats our skin and hair.
                    In order to prevent them from drying out, sebum travels up
                    hair follicles and out through our pores onto the surface of
                    our skin. Our hair follicles routinely shed dead skin cells,
                    with sebum carried out of your body. Extra sebum and dead
                    skin cells produced by our bodies get stuck together and
                    clog our pores, resulting in skin blemishes. Then the
                    bacteria that normally exist in small amounts on your skin,
                    can flourish in the sebum in the clogged pores, leading to
                    inflammation.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Whiteheads:&nbsp; </strong>
                  <span style={{ fontWeight: 400 }}>
                    Depending on where the clog is located, and if you’ve
                    inflammation, acne may appear as whiteheads, which are
                    clogged follicles closed off from the air.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Blackheads: </strong>
                  <span style={{ fontWeight: 400 }}>
                    The clogged follicles that turn a darker color when the clog
                    is exposed to air are called blackheads.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Pustules: </strong>
                  <span style={{ fontWeight: 400 }}>
                    Pustules are commonly known as pimples, which are inflamed
                    follicles clogged with pus.
                  </span>
                </p>
                <p>
                  <strong>Cyst: </strong>
                  <span style={{ fontWeight: 400 }}>
                    Cysts are larger, painful pus-filled lumps going deep under
                    the skin.
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You’ve come to think about how acne does damage. Now let’s
                    talk about how to treat it quickly and effectively.
                    Unfortunately, there’s no magic pill to treat the 20
                    different sub-types of acne. However, to achieve the
                    long-lasting cure you’re looking for, you need to start with
                    a correct diagnosis and then follow a customized treatment
                    plan.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>FACTORS CONTRIBUTING TO ACNE:&nbsp;</strong>
                </p>
                <ul>
                  <li aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Hormonal changes</span>
                  </li>
                </ul>
                <ul>
                  <li aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Bacteria</span>
                  </li>
                </ul>
                <ul>
                  <li aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Certain medication</span>
                  </li>
                </ul>
                <ul>
                  <li aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Genetics</span>
                  </li>
                </ul>
                <p>
                  <strong>
                    If you’re a mild case of acne, your doctor may recommend an
                    over-the-counter lotion with one of several active
                    ingredients:
                  </strong>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Benzoyl peroxide</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Salicylic acid</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Alpha hydroxy acid</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Sulfur</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default AntiAcneTreatment;
