import React from "react";

function OfferSection() {
  return (
    <div
      className="section call-to-action-serction"
      style={{ backgroundImage: "url(assets/images/patan-bg-2.webp)" }}
    >
      {/* Call to Action bg Start */}
      <div
        className="call-to-action-bg"
        style={{ backgroundImage: "url(assets/images/offer.jpg)" }}
      />
      {/* Call to Action bg End */}
      {/* Call to Action Content Start */}
      <div className="call-to-action-content section-padding-02">
        <img
          className="shape-1 movebounce-01"
          width={194}
          height={227}
          src="assets/images/shape/shape-10.webp"
          alt="Shape"
        />
        <img
          className="shape-2"
          src="assets/images/shape/shape-11.webp"
          width={146}
          height={145}
          alt="Shape"
        />
        {/* Call to Action Wrapper Start */}
        <div
          className="call-to-action-wrapper text-center"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          {/* Section Title Start */}
          <div className="section-title text-center">
            <h6 className="sub-title">Special Offer</h6>
            <h2 className="title">WE HAVE OFFERS THAT BRING JOY!</h2>
            <p>GET UP TO 20% OFF ON OUR TREATMENTS</p>
          </div>
          {/* Section Title End */}
          <h4 className="discount">Limited time offer – Hurry up!</h4>
          <a
            href="#"
            className="btn btn-primary btn-hover-dark"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            Book Now
          </a>
        </div>
        {/* Call to Action Wrapper End */}
        <img
          className="shape-3 movebounce-03"
          src="assets/images/shape/shape-12.webp"
          width={216}
          height={190}
          alt="Shape"
        />
        <img
          className="shape-4 movebounce-02"
          src="assets/images/shape/shape-13.webp"
          width={363}
          height={231}
          alt="Shape"
        />
      </div>
      {/* Call to Action Content End */}
    </div>
  );
}

export default OfferSection;
