import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function VampireFacial() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Vampire Facial"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>VAMPIRE FACIAL</strong>
                    </h2>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Vampire facial, also known as Face PRP Therapy
                        (Platelet-rich plasma), is a facial rejuvenation
                        procedure. This procedure utilizes the plasma extracted
                        from the blood and helps in the reduction of fine lines,
                        wrinkles, folds, and open pores by stimulating collagen
                        production.
                      </span>
                    </p>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        It is a revolutionary process that is effective in
                        treating acne scars, pigmentation, lines, wrinkles,
                        blotchy skin, and anti-aging treatment for total facial
                        rejuvenation.
                      </span>
                    </p>

                    <p>
                      <strong>
                        THE PRP THERAPY/VAMPIRE FACIAL PROCEDURE INCLUDES:
                      </strong>
                    </p>

                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          The procedure is performed under local anesthesia.
                          Hence, the procedure is painless.
                        </span>
                      </li>
                    </ul>

                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          The blood is extracted from the vein in the arm
                          (approx. 30 ml) and placed in a device called a
                          centrifuge machine for around eight to ten minutes to
                          get the plasma separated from the rest of the blood.
                        </span>
                      </li>
                    </ul>

                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          The plasma that was separated is injected all over the
                          face using a thin needle syringe or microneedle.&nbsp;
                        </span>
                      </li>
                    </ul>

                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Finally, ice is applied to the face to minimize
                          swelling and bruising.
                        </span>
                      </li>
                    </ul>

                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          The entire process takes around 45 minutes to one
                          hour.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <strong>
                        HOW LONG DOES IT TAKE TO SHOW THE RESULTS?
                      </strong>
                    </p>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        The results may vary from person to person. Usually, it
                        takes around two to three weeks after the procedure to
                        see visible outcomes in the skin and around four to six
                        weeks to see a marked difference. The results from the
                        vampire facial treatment can last for at least two
                        years.
                      </span>
                    </p>

                    <p>
                      <strong>WHAT ARE THE BENEFITS OF VAMPIRE FACIAL?</strong>
                    </p>

                    <p>
                      <strong>The procedure has the following benefits:</strong>
                    </p>

                    <ul>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Helps in the reduction of wrinkles.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Helps in plumping up the sagging skin.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Helps get rid of deep creases.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Enhances skin complexion.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Helps diminish acne scars.
                        </span>
                      </li>
                      <li style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 400 }}>
                          Overall, facial rejuvenation.
                        </span>
                      </li>
                    </ul>

                    <p>
                      <span style={{ fontWeight: 400 }}>
                        To stay safe, always choose a qualified professional to
                        perform the procedure.&nbsp; While performing the
                        procedure, the dermatologist &amp; therapists should
                        follow proper hygiene techniques and should sterilize
                        the equipment before and after the procedure.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default VampireFacial;
