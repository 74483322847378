import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function Cryolipolysis() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Cryolipolysis"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>CRYOLIPOLYSIS (FAT FREEZE)</strong>
                  </h2>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Cryolipolysis is a body contouring treatment that has more
                      than 1 million fans worldwide. There are several
                      procedures available that are being done for weight loss
                      and this is indeed a reality. The best part of the
                      treatment is that the procedure is non-surgical and
                      non-invasive. The procedure along with a customized diet
                      makes the result last a lot longer, even permanent in
                      certain cases, and changes their lives forever.
                    </span>
                  </p>

                  <p>
                    <strong>HOW PROCEDURE IS DONE?</strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      After the doctor &amp; therapist select the area(s) to be
                      treated, the device is positioned on your body and
                      controlled cooling is applied. During the procedure, you
                      can do any activity like reading, checking mail or you can
                      even take a nap.
                    </span>
                  </p>

                  <p>
                    <strong>WHAT ARE THE BENEFITS OF CRYOLIPOLYSIS?</strong>
                  </p>

                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <strong>RESHAPE YOUR BODY: </strong>
                      <span style={{ fontWeight: 400 }}>
                        The fat can be reduced on multiple areas of your body
                        with this procedure, and additional sessions can help in
                        enhancing the results.&nbsp;
                      </span>
                    </li>
                  </ul>

                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <strong>GET USED TO LIKING WHAT YOU SEE:</strong>
                      <span style={{ fontWeight: 400 }}>
                        {" "}
                        In the following weeks and months of the procedure, the
                        body naturally processes and eliminates the treated fat
                        cells. Once the fat cells are gone, they’re gone for
                        good.
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>TREATMENT PLAN</strong>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Before starting the procedure, a proper consultation is
                        required. Once everything is reviewed, then the
                        procedure is done.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Depending on the requirements of the body, the treatment
                        area and number of targeted spots are chosen.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        One session can last from 1-3 hour, depending on the
                        size of the target area.&nbsp;
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Typical results are 1-3 inches within 30-90 days.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        The same procedure is performed after 45-90 days for
                        future inch loss.
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>
                      WHAT TO EXPECT AFTER THE CRYOLIPOLYSIS SESSION?
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Cryolipolysis is a fat freezing procedure that is
                      completely non-surgical, so you can typically return to
                      normal activities immediately. You can follow the basic
                      instructions as directed by the doctor.
                    </span>
                  </p>

                  <p>
                    <strong>
                      IN WHICH AREAS CRYOLIPOLYSIS SESSIONS CAN BE DONE?
                    </strong>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      The procedure can be done in any of the following areas
                      depending on the patient’s requirements:
                    </span>
                  </p>

                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Abdomen</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Back</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Thighs</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Buttocks</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Flanks</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Arms</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      So, what are you waiting for? Visit us and get the body
                      you always wanted, at{" "}
                    </span>
                    <strong>Swakaaya Wellness Clinic</strong>
                    <span style={{ fontWeight: 400 }}>!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Cryolipolysis;
