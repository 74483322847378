import React from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function PigmentationRemoval() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Pigmentation Removal Treatment"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>PIGMENTATION REMOVAL TREATMENT</strong>
                  </h2>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Hyperpigmentation or excess pigmentation is a common sign
                      of sun damage that a lot of women accumulate as they get
                      older, as the skin is exposed to more and more sun
                      throughout the years.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Pigmentation is just a technical term which is the
                      darkening of the skin.
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Dark circle</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Acne marks</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Sun tanning</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Freckles</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      All these come under the umbrella of pigmentation.
                    </span>
                  </p>

                  <p>
                    <strong>WHAT CAUSES PIGMENTATION?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Humans have certain pigment cells and these pigment cells
                      can get attracted to the sun, which is why they come out
                      on the surface and we look darker, so this is tanning. In
                      India, people are exposed a lot to the sun.&nbsp;
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Sun exposure</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Genetics</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Scars &amp; blemishes
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>
                        Medical pigmentation
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Freckles</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Sunspots</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Age spots</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Friction</span>
                    </li>
                    <li style={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 400 }}>Melasma</span>
                    </li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If you’ve experimented on all the home remedies and still
                      not getting any results, then it’s high time for you to
                      visit a dermatologist.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>
                      HOW CAN WE REMOVE PIGMENTATION OF OUR FACES
                      PERMANENTLY?&nbsp;
                    </strong>
                  </p>
                  <p>
                    <strong>Let’s see the treatment options:</strong>
                  </p>
                  <p>
                    <strong>
                      ANTI-PIGMENTATION CREAM PRESCRIBED BY DERMATOLOGISTS:{" "}
                    </strong>
                    <span style={{ fontWeight: 400 }}>
                      Creams that are prescribed by doctors contain active
                      ingredients that lower the levels of melanin in the skin.
                      These creams can be used to improve the appearance of dark
                      spots and also help to prevent them and treat skin issues
                      like melasma.&nbsp;&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>CHEMICAL PEELS: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Chemical Peel is another kind of pigmentation treatment
                      that can improve the appearance of the skin by peeling off
                      an older layer of skin to reveal the more youthful and
                      healthy skin underneath.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>PHOTO FACIAL TO REDUCE PIGMENTATION: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Photo Facial is used to improve the appearance of
                      sun-damaged skin, as well as remove brown and red spots,
                      resulting in the most youthful-looking skin.
                    </span>
                  </p>

                  <p>
                    <strong>LASER TREATMENT: </strong>
                    <span style={{ fontWeight: 400 }}>
                      If the above treatment doesn’t work on your skin, then you
                      can opt for a pigmentation laser.&nbsp;
                    </span>
                  </p>

                  <p>
                    <strong>SUNSCREEN &amp; SUN PROTECTION MEASURES: </strong>
                    <span style={{ fontWeight: 400 }}>
                      Don’t forget to use sun protection creams every time you
                      go out. Otherwise, the above treatments will not work.
                    </span>
                  </p>

                  <p>
                    <strong>
                      WILL PIGMENTATION RECUR AFTER YOU STOP THE TREATMENT?
                    </strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      It sometimes comes back even after successful treatment.
                      Since it has nothing to do with finishing treatment.
                      Excessive exposure to the sun and hormones post-treatment
                      increases the chance of pigmentations showing up again. We
                      can’t stop these things from coming back again and again,
                      but we can take some preventive measures. Make sure you’re
                      using sun-protection creams and sun protection measures.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Treatments can only remove pigmentation from your skin, it
                      cannot lower the chances of getting pigmentation again.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If you’re facing problems like these, then{" "}
                    </span>
                    <strong>Swakaaya Wellness</strong> <strong>Clinic</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      is here to lend a helping hand!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default PigmentationRemoval;
