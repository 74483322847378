import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function LaserHairReduction() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Laser Hair Reduction"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <h2>
                    <strong>LASER HAIR REDUCTION</strong>
                  </h2>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If you are planning to get laser hair reduction treatment
                      and want to know the best cost for quality and painless
                      laser hair removal for either men or women, then{" "}
                    </span>
                    <strong>SWAKAAYA WELLNESS CLINIC</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      is the right clinic.
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If you are fed up with extra body hair growth and are
                      tired from old methods of risky hot waxing or irritated by
                      shaving your legs, arms or on other body parts regularly,
                      then Laser Hair Reduction is the best option for you.
                      Laser Hair Reduction has become one of the safest and
                      easiest ways in today's world of cosmetics to get smooth
                      and hair-free skin.&nbsp;
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      During the procedure, the lasers emit light at a specific
                      wavelength. Then it is absorbed by the pigment of the hair
                      follicle without damaging the skin around it. The light is
                      converted into heat energy. Hence, this removes the hair
                      follicle, therefore, preventing hair growth.
                    </span>
                  </p>

                  <p>
                    <strong>
                      WHAT ARE THE BENEFITS OF LASER HAIR REDUCTION?
                    </strong>
                  </p>

                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Safe, effective and popular procedure.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Silky &amp; smooth skin
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Long-lasting results
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Quick &amp; painless procedure
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Eliminate ingrown hairs
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>
                      PRE-LASER HAIR REDUCTION TREATMENT PREPARATIONS:
                    </strong>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Don’t shave 10-15 days before the session.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Avoid excessive sun exposure.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Don’t go for any salon activity 7 days before the
                        session.
                      </span>
                    </li>
                  </ul>

                  <p>
                    <strong>HOW MANY TREATMENTS ARE REQUIRED?</strong>
                  </p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      It will take about six to eight sessions to achieve a full
                      clear area. But, in some cases, there is a need for four
                      treatments only.
                    </span>
                  </p>

                  <p>
                    <span style={{ fontWeight: 400 }}>
                      If you wish to garner more details on any concern within
                      this or beyond, then we are available for you!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default LaserHairReduction;
