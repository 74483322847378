import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyChooseUs from "../../components/whychooseus/WhyChooseUs";
import Breadcrumb from "../common/Breadcrumb";
import Footer from "../common/Footer";
import Header from "../common/Header";

function ChinEnhancement() {
  return (
    <div>
      <Header />
      <Breadcrumb title={"Chin Enhancement"} />
      <section className="section section-padding-02 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service-content">
                <div>
                  <div>
                    <h2>
                      <strong>Chin Enhancement</strong>
                    </h2>

                    <div>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          A balanced facial profile begins with a balanced chin.
                          An improved balance and proportion can be achieved by
                          adding volume to your chin. As you age, your chin and
                          jawline may lose their youthful definition. Chin
                          enhancement treatment promises to fix the following
                          problems:
                        </span>
                      </p>

                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>A thick neck</span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Sagging jowls</span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Asymmetrical chin
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            A pointed or square chin structure
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Weak jawline</span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Double chin</span>
                        </li>
                      </ul>

                      <p>
                        <strong>Treatment options-</strong>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Chin enhancement with fillers
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>Chin implant</span>
                        </li>
                      </ul>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          You can choose between a surgical or nonsurgical chin
                          augmentation to treat weak, receding, or uneven chins.
                          Both treatments have advantages and benefits, and our
                          dermatologist will guide you to choose the most
                          suitable option for you.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Am I a good candidate for chin enhancement?
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          If you don’t feel confident with the shape of your
                          chin, then you can opt for this treatment.&nbsp;
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Chin enhancement with filler is a non-surgical
                          treatment designed to balance the profile and define
                          the jawline and also to tighten up the skin under the
                          jaw using derma filler injections. This treatment
                          takes 20-30 minutes to complete and you can see the
                          result immediately. There is no need for anesthesia,
                          stitches, bandages, or scarring to augment the chin
                          with fillers. Work and other daily activities can
                          usually be resumed immediately.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          A chin implant is a surgical procedure that lasts for
                          a lifetime. It is normal for some swelling, redness,
                          or bruising to occur following the procedure. You can
                          reduce swelling faster by sleeping with your head and
                          neck elevated. To ensure that the filler settles in
                          the right places, be gentle with your chin and jaw
                          area after your procedure. Avoid rubbing or touching
                          the treated areas. The result is immediately visible
                          and it lasts for 12 to 24 months.&nbsp;
                        </span>
                      </p>

                      <p>
                        <strong>How does it work?</strong>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>In </span>
                        <strong>chin enhancement with filler</strong>
                        <span style={{ fontWeight: 400 }}>
                          , our doctors will first ask you some questions to
                          understand your requirements and later he/she will
                          prescribe you some medications before the treatment.
                          Depending on your aesthetic goals, doctors will
                          recommend the best treatment for you.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          During the treatment, the doctors will first apply
                          numbing cream on the targeted area to make you feel
                          comfortable and then they will inject an FDA-approved
                          hyaluronic acid-based solution to make your chin more
                          attractive.
                        </span>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>In </span>
                        <strong>Chin implant</strong>
                        <span style={{ fontWeight: 400 }}>
                          , a surgeon begins the process by-
                        </span>
                      </p>
                      <ul>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Making an incision either inside the mouth or in the
                            natural crease under the person's chin.
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            Then, by stretching the tissue gently, the surgeon
                            creates a space to insert the tissue, which is made
                            of synthetic material that feels like natural
                            tissue.&nbsp;
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }} aria-level={1}>
                          <span style={{ fontWeight: 400 }}>
                            The surgeon later uses a fine suture to close the
                            incision. If the incision is inside the mouth, no
                            scar will be visible and if the incision is under
                            the chin, the scar is usually imperceptible.
                          </span>
                        </li>
                      </ul>

                      <p>
                        <strong>Why choose Swakaaya Wellness Clinic?</strong>
                      </p>

                      <p>
                        <span style={{ fontWeight: 400 }}>
                          At Swakaaya Wellness Clinic, we provide both surgical
                          and non-surgical chin enhancement treatment. With our
                          experienced and well-trained cosmetic surgeons,
                          patients get positive results only.&nbsp;
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyChooseUs />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default ChinEnhancement;
